import {
  LOAD_USER_REFERRAL_IDENTIFIER_REQUEST,
  LOAD_USER_REFERRAL_IDENTIFIER_SUCCESS,
  LOAD_USER_REFERRAL_IDENTIFIER_ERROR
} from '~/redux/actions/types'

export const loadUserReferralIdentifierRequest = () => ({
  type: LOAD_USER_REFERRAL_IDENTIFIER_REQUEST
})

export const loadUserReferralIdentifierSuccess = referralIdentifier => ({
  type: LOAD_USER_REFERRAL_IDENTIFIER_SUCCESS,
  payload: {
    referralIdentifier
  }
})

export const loadUserReferralIdentifierError = error => ({
  type: LOAD_USER_REFERRAL_IDENTIFIER_ERROR,
  payload: error,
  error: true
})

export const getOrLoadUserReferralIdentifier =
  email => async (dispatch, getState) => {
    let user = getState().user
    if (user.referralIdentifier) return user.referralIdentifier
    return await dispatch(loadUserReferralIdentifier(email))
  }

export const loadUserReferralIdentifier =
  email =>
  async (dispatch, _getState, { api }) => {
    dispatch(loadUserReferralIdentifierRequest())
    try {
      const response = await api.fetchUserReferralIdentifier(email)
      dispatch(loadUserReferralIdentifierSuccess(response))
      return response
    } catch (error) {
      dispatch(loadUserReferralIdentifierError(error))
      throw error
    }
  }
