import {
  FINALIZE_CHECKOUT_REQUEST,
  FINALIZE_CHECKOUT_SUCCESS,
  FINALIZE_CHECKOUT_ERROR
} from '~/redux/actions/types'

export const finalizeCheckoutRequest = () => {
  return {
    type: FINALIZE_CHECKOUT_REQUEST
  }
}

export const finalizeCheckoutSuccess = cart => {
  return {
    type: FINALIZE_CHECKOUT_SUCCESS,
    cart
  }
}

export const finalizeCheckoutError = error => {
  return {
    type: FINALIZE_CHECKOUT_ERROR,
    error: true,
    payload: error
  }
}

export const finalizeCheckout =
  ({ orderNumber, checkoutSessionId }) =>
  async (dispatch, _getState, { api }) => {
    dispatch(finalizeCheckoutRequest())

    try {
      let finalizeOrderParams = {}

      // Left here for backwards compatibility: old starter versions are not
      // sending those params and we will have to get them from the location.search
      // using getFinalizeCheckoutParams().
      if (orderNumber && checkoutSessionId) {
        finalizeOrderParams = { orderNumber, checkoutSessionId }
      } else {
        console.warn(
          'You are setting the order number and checkout_session_id via URL query parameters, which is deprecated. Please switch to the new way of passing these information using the /order/confirmation/#{order.number}/{CHECKOUT_SESSION_ID} format.'
        )
        finalizeOrderParams = getFinalizeCheckoutParams()
      }

      const cart = await api.finalizeCheckout(finalizeOrderParams)
      dispatch(finalizeCheckoutSuccess(cart))
      return cart
    } catch (error) {
      dispatch(finalizeCheckoutError(error))
      throw error
    }
  }

/**
 * @deprecated
 *
 * Left here for backwards compatibility. Checkout params should be fetched
 * from the starter Order Confirmation page instead, then passed to the
 * `finalizeCheckout` method.
 */
const getFinalizeCheckoutParams = () => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  return {
    orderNumber: params.get('number'),
    checkoutSessionId: params.get('checkout_session_id')
  }
}
