import {
  LOAD_USER_SUBSCRIPTIONS_REQUEST,
  LOAD_USER_SUBSCRIPTIONS_SUCCESS,
  LOAD_USER_SUBSCRIPTIONS_ERROR,
} from "../types";

export const loadUserSubscriptionsRequest = () => ({
  type: LOAD_USER_SUBSCRIPTIONS_REQUEST,
});

export const loadUserSubscriptionsSuccess = (subscriptions) => ({
  type: LOAD_USER_SUBSCRIPTIONS_SUCCESS,
  payload: {
    subscriptions,
  },
});

export const loadUserSubscriptionsError = (error) => ({
  type: LOAD_USER_SUBSCRIPTIONS_ERROR,
  payload: error,
  error: true,
});

export const loadUserSubscriptions =
  () =>
  async (dispatch, getState, { api }) => {
    const { user } = getState();
    const userId = user.data.id;

    dispatch(loadUserSubscriptionsRequest());
    try {
      const response = await api.fetchSubscriptions(userId);
      dispatch(loadUserSubscriptionsSuccess(response));
    } catch (error) {
      dispatch(loadUserSubscriptionsError(error));
      throw error;
    }
  };
