// eslint-disable-next-line import/no-anonymous-default-export
export default {
  accountCredit: {
    data: [],
    isFetching: false,
    error: null,
  },
  addressBook: {
    data: [],
    isFetching: false,
    error: null,
  },
  auth: {
    isFetching: false,
    isLoggedIn: false,
    token: null,
  },
  cart: {
    data: {},
    isFetching: false,
    error: null,
    promoCode: {
      isFetching: false,
    },
  },
  catalog: {
    data: [],
    isFetching: false,
    error: null,
  },
  order: {
    data: {},
    isFetching: false,
    error: null,
  },
  orders: {
    data: [],
    isFetching: false,
    error: null,
  },
  user: {
    data: {},
    referralIdentifier: null,
    subscriptions: [],
  },
  subscription: {
    data: {},
    isFetching: false,
    isLoaded: false,
    error: null,
  },
};
