import { INITIALIZE_STATE_FROM_STORAGE } from "../types";
import storage from "../../../services/storage";
import { loadCart, addReferralIdentifier } from "../cart";
import { handleCallback } from "../auth";
import { getParam, setCookieFromParam } from "../../../utils";

const clearFinalizedOrderFromStorage = () => {
  try {
    const checkoutSessionId = getParam("checkout_session_id");
    const finalizedOrderNumber = getParam("number");
    if (
      checkoutSessionId &&
      finalizedOrderNumber &&
      finalizedOrderNumber === storage.getItem("cart-number")
    ) {
      storage.removeItem("cart-number");
      storage.removeItem("cart-token");
    }
  } catch (error) {
    console.error(error);
  }
};

export const initializeStateFromStorage = () => {
  const loggedIn = storage.getItem("auth-logged-in");
  const payload = {
    auth: {
      isLoggedIn: loggedIn === "true" || loggedIn === true,
    },
    cart: {
      data: {
        number: storage.getItem("cart-number"),
        token: storage.getItem("cart-token"),
      },
    },
  };

  return {
    type: INITIALIZE_STATE_FROM_STORAGE,
    payload,
  };
};

const persistTrackingParams = () => {
  setCookieFromParam("ir_click_id");
};

export const initializeState = () => async (dispatch) => {
  clearFinalizedOrderFromStorage();

  try {
    await dispatch(initializeStateFromStorage());
  } catch (error) {
    console.error(error);
  }

  try {
    await dispatch(loadCart());
  } catch (error) {
    console.error(error);
  }

  if (process.env.NEXT_PUBLIC_COGNITO_IDENTITY_SERVICE_URL) {
    const authToken = getParam("code");
    if (authToken) {
      try {
        await dispatch(handleCallback());
      } catch (error) {
        console.error(error);
      }
    }
  }

  const referralIdentifier = getParam("ref");
  if (referralIdentifier) {
    try {
      dispatch(addReferralIdentifier(referralIdentifier));
    } catch (error) {
      console.error(error);
    }
  }

  persistTrackingParams();
};
