import {
  AUTH_LOGOUT_ERROR,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS
} from '~/redux/actions/types'

import { forgetCart } from '~/redux/actions/cart/forget-cart'
import { clearSession } from './clear-session'
import auth from '~/services/auth'

export const logoutRequest = () => {
  return {
    type: AUTH_LOGOUT_REQUEST
  }
}

export const logoutSuccess = payload => {
  return {
    type: AUTH_LOGOUT_SUCCESS,
    payload
  }
}

export const logoutError = error => {
  return {
    type: AUTH_LOGOUT_ERROR,
    error: true,
    payload: {
      error
    }
  }
}

export const logout = () => async dispatch => {
  try {
    dispatch(logoutRequest())
    const metadata = await auth.getUser()
    await auth.logout()
    await dispatch(logoutSuccess({ email: metadata.email }))
    await dispatch(forgetCart())
    await dispatch(clearSession())
  } catch (error) {
    dispatch(logoutError(error))
    throw error
  }
}
