import {
  LOAD_ACCOUNT_CREDIT_REQUEST,
  LOAD_ACCOUNT_CREDIT_SUCCESS,
  LOAD_ACCOUNT_CREDIT_ERROR,
} from "../types";

export const loadAccountCreditRequest = () => ({
  type: LOAD_ACCOUNT_CREDIT_REQUEST,
});

export const loadAccountCreditSuccess = (data) => ({
  type: LOAD_ACCOUNT_CREDIT_SUCCESS,
  payload: {
    data,
  },
});

export const loadAccountCreditError = (error) => ({
  type: LOAD_ACCOUNT_CREDIT_ERROR,
  payload: error,
  error: true,
});

export const loadAccountCredit =
  (chordUser) =>
  async (dispatch, _getState, { api }) => {
    dispatch(loadAccountCreditRequest());
    try {
      const response = await api.fetchAccountCredit(chordUser);
      dispatch(loadAccountCreditSuccess(response));
    } catch (error) {
      dispatch(loadAccountCreditError(error));
      throw error;
    }
  };
