import React, { FunctionComponent, useEffect, PropsWithChildren } from "react";
import Router from "next/router";
import { AnalyticsSnippet } from "@segment/analytics-next";

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
  }
}

type PageTrackerProps = PropsWithChildren<{}>;
export const PageTracker: FunctionComponent<PageTrackerProps> = ({
  children,
}) => {
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      window.analytics.page(url);
    };
    Router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);
  return <>{children}</>;
};
