import {
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_IDENTIFY_ERROR,
  AUTH_IDENTIFY_REQUEST,
  AUTH_IDENTIFY_SUCCESS,
  INITIALIZE_STATE_FROM_STORAGE,
} from "../actions/types";
import initialState from "../store/initial-state";

// eslint-disable-next-line default-param-last
const auth = (state = initialState.auth, action) => {
  switch (action.type) {
    case INITIALIZE_STATE_FROM_STORAGE:
      return {
        ...state,
        isLoggedIn: !!action.payload.auth.isLoggedIn,
        token: action.payload.auth.token,
        isFetching: !!action.payload.auth.isFetching,
      };

    case AUTH_IDENTIFY_ERROR:
    case AUTH_LOGIN_ERROR:
      return {
        ...state,
        isFetching: false,
        isLoggedIn: false,
        token: null,
      };

    case AUTH_IDENTIFY_REQUEST:
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case AUTH_IDENTIFY_SUCCESS:
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoggedIn: true,
        token: action.payload.token,
      };

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoggedIn: false,
        token: null,
      };

    default:
      return state;
  }
};

export default auth;
