import { modifyCart } from './modify-cart'
import { getOrLoadCart } from './get-or-load-cart'

export const addReferralIdentifier = referralIdentifier => async (
  dispatch,
  getState
) => {
  const cart = await getOrLoadCart(dispatch, getState)

  const currentReferralIdentifier =
    cart && cart.data && cart.data.referralIdentifier

  if (referralIdentifier && currentReferralIdentifier !== referralIdentifier) {
    dispatch(modifyCart({ referralIdentifier }))
  }
}
