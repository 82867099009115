/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */
/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler = (message, violations) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)
	console.warn(msg)
}
let analytics = () => {
	return window.analytics
}
/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}
/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message = {}) {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'javascript',
				version: '7.4.1',
			},
		},
	}
}
/**
 * @typedef Context
 * @property {string} [group_id] - The id of the account being created/updated/deleted
 */
/**
 * @typedef AccountCreated
 * @property {Record<string, any>} [] -
 * @property {string} account_name - The name of the account being created
 * @property {Context} context - The context array
 */
/**
 * @typedef AmbassadorSignupCreated
 * @property {string} email - Visitor's email address
 * @property {string} [instagram] - Visitor's Instagram handle
 * @property {string} name - Visitor's name
 * @property {string} phone_number - Visitor's phone number
 * @property {boolean} social_ambassador - "I'm interested in becoming a HIKI Social Ambassador"
 * @property {string} state - Visitor's U.S. state
 * @property {boolean} sweattalk_host_ambassador - "I'm interested in becoming a HIKI SweatTalk Host Ambassador"
 * @property {string} [tiktok] - Visitor's TikTok handle
 */
/**
 * @typedef ProductsItem
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} coupon - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} url - URL of the product page for product in the list
 * @property {string} variant - Product Variant displayed in the list
 */
/**
 * @typedef CartViewed
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {ProductsItem[]} products - Products displayed in the product list
 */
/**
 * @typedef Ownership
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store
 * @property {string} domain -
 */
/**
 * @typedef Meta
 * @property {Ownership} ownership -
 * @property {Platform} platform -
 * @property {Store} store -
 */
/**
 * @typedef ProductsItem1
 * @property {string} [product_description] -
 * @property {string} product_id -
 * @property {string} product_image_url -
 * @property {string} product_name -
 * @property {string} product_slug -
 * @property {number} [quantity] -
 */
/**
 * @typedef CheckoutAbandoned
 * @property {Meta} meta -
 * @property {string} [order_cart_url] -
 * @property {string} [order_id] -
 * @property {string} [order_name] -
 * @property {ProductsItem1[]} [products] -
 * @property {string} [user_display_name] -
 * @property {string} [user_email] -
 * @property {string} [user_id] -
 * @property {string} [user_phone] -
 */
/**
 * @typedef TrackingIds
 * @property {Record<string, any>} [ir_click_id] - impact radius tracking pixel
 * @property {Record<string, any>} [tap_joy_id] - tap joy tracking pixel
 */
/**
 * @typedef CheckoutCompleted
 * @property {string} [currency] - Order currency
 * @property {number} [discount] - discount amount from coupon code
 * @property {string} [email_hash] - hash of the customer's email address to send 3rd party providers
 * @property {Record<string, any>[]} [products] - array of products purchased
 * @property {string} [store] - store name
 * @property {TrackingIds} [tracking_ids] - 3rd Tracking pixels (ex. IR, Tap Joy)
 * @property {string} [transaction_id] - Order number
 * @property {string} value - Order total
 */
/**
 * @typedef ProductsItem2
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} coupon - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} url - URL of the product page for product in the list
 * @property {string} variant - Product Variant displayed in the list
 */
/**
 * @typedef CheckoutStarted
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} discount - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 * @property {ProductsItem2[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} shipping - Shipping cost associated with the transaction
 * @property {number} tax - Total tax associated with the transaction
 * @property {number} total - Revenue with discounts and coupons added
 */
/**
 * @typedef CheckoutStepCompleted
 * @property {string} [checkout_id] - Checkout transaction ID
 * @property {string} [payment_method] - String representing the payment method chosen
 * @property {string} [shipping_method] - String representing the shipping method chosen
 * @property {string} step - Number representing a step in the checkout process
 */
/**
 * @typedef CheckoutStepViewed
 * @property {string} [checkout_id] - Checkout transaction ID
 * @property {string} [payment_method] - String representing the payment method chosen
 * @property {string} [shipping_method] - String representing the shipping method chosen
 * @property {string} step - Number representing a step in the checkout process
 */
/**
 * @typedef CollectionClicked
 * @property {string} brand - Brand of the collection
 * @property {string} collection_id - Database ID of the collection
 * @property {string} title - Title of the collection
 * @property {string} url - URL of the collection page for the collection
 */
/**
 * @typedef CouponApplied
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} coupon_id - ID of the coupon
 * @property {string} [coupon_name] - Name of the coupon
 * @property {number} discount - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 */
/**
 * @typedef CouponDenied
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} coupon_id - ID of the coupon
 * @property {string} [coupon_name] - Name of the coupon
 * @property {string} order_id - Order/transaction ID
 * @property {string} [reason] - Reason the coupon was denied
 */
/**
 * @typedef CouponEntered
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} coupon_id - ID of the coupon
 * @property {string} [coupon_name] - Name of the coupon
 * @property {string} order_id - Order/transaction ID
 */
/**
 * @typedef CouponRemoved
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} coupon_id - ID of the coupon
 * @property {string} [coupon_name] - Name of the coupon
 * @property {number} discount - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 */
/**
 * @typedef DeliveryStatus
 * @property {string} [code] - The code returned by the server
 * @property {string} [description] - The reason of the failed attempt
 */
/**
 * @typedef EmailBounced
 * @property {string} [campaign_id] - An id used to identify a campaign
 * @property {string} [campaign_name] - A name used to identify a campaign
 * @property {DeliveryStatus} [delivery_status] - The error status details
 * @property {string} email_id - An ID used to identify the email.
 * @property {string} [email_subject] - The email’s subject line.
 */
/**
 * @typedef WebPlacement
 * @property {string} [component] - HTML component within the UX, such as header, footer, modal
 * @property {string} [page] - Page of website (or "Sitewide" for whole website)
 * @property {string} [website] - Hostname of website
 */
/**
 * @typedef EmailCaptured
 * @property {string} [email] - Email address added to the form
 * @property {WebPlacement} [web_placement] -
 */
/**
 * @typedef EmailDelivered
 * @property {string} [campaign_id] - An id used to identify a campaign
 * @property {string} [campaign_name] - A name used to identify a campaign
 * @property {string} email_id - An ID used to identify the email.
 * @property {string} [email_subject] - The email’s subject line.
 */
/**
 * @typedef EmailLinkClicked
 * @property {string} [campaign_id] - An id used to identify a campaign
 * @property {string} [campaign_name] - A name used to identify a campaign
 * @property {string} email_id - An ID used to identify the email.
 * @property {string} [email_subject] - The email’s subject line.
 * @property {string} [link_id] - An id used to identify a link
 * @property {string} link_url - The URL the link points to.
 */
/**
 * @typedef EmailMarkedAsSpam
 * @property {string} [campaign_id] - An id used to identify a campaign
 * @property {string} [campaign_name] - A name used to identify a campaign
 * @property {string} email_id - An ID used to identify the email.
 * @property {string} [email_subject] - The email’s subject line.
 */
/**
 * @typedef EmailOpened
 * @property {string} [campaign_id] - An id used to identify a campaign
 * @property {string} [campaign_name] - A name used to identify a campaign
 * @property {string} email_id - An ID used to identify the email.
 * @property {string} [email_subject] - The email’s subject line.
 */
/**
 * @typedef InviteSent
 * @property {string} invitee_email - Email address of the person receiving the invite
 * @property {string} [invitee_first_name] - First name of the person receiving the invite
 * @property {string} [invitee_last_name] - Last name of the person receiving the invite
 * @property {string} [invitee_role] - Role of the invitee
 */
/**
 * @typedef Ownership1
 * @property {string} store_id -
 * @property {string} tenant_id -
 */
/**
 * @typedef Platform1
 * @property {string} name -
 * @property {string} type -
 */
/**
 * @typedef Store1
 * @property {string} domain -
 */
/**
 * @typedef Meta1
 * @property {Ownership1} ownership -
 * @property {Platform1} platform -
 * @property {Store1} store -
 */
/**
 * @typedef Product
 * @property {string} description -
 * @property {string} id -
 * @property {string} slug -
 */
/**
 * @typedef StockRequest
 * @property {string} email -
 * @property {string} id -
 */
/**
 * @typedef Variant
 * @property {string} backorderable -
 * @property {string} cost -
 * @property {string} id -
 * @property {string} image_url -
 * @property {string} sku -
 * @property {string} total_on_hand -
 * @property {string} url -
 */
/**
 * @typedef ItemRestocked
 * @property {Meta1} meta -
 * @property {Product} [product] -
 * @property {StockRequest} [stock_request] -
 * @property {Variant} [variant] -
 */
/**
 * @typedef ProductsItem3
 * @property {string} brand - Product Brand displayed in the list
 * @property {boolean} bundle - Is the item a bundle?
 * @property {number} [bundle_size] - For line items that are bundles, how many products are contained within the bundle
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} description - Short description of product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {string} [product_sku] - Product SKU
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product variant SKU displayed in the list
 * @property {string} url - URL of the product page for product in the list
 */
/**
 * @typedef OrderCompleted
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} discount - Total discount associated with the transaction
 * @property {string} email - Email address associated with order
 * @property {boolean} [has_subscription] - Order has at least one subscription in cart?
 * @property {boolean} [is_recurring] - Order was generated by a subscription?
 * @property {string} order_date - Date order was placed
 * @property {string} order_id - Order/transaction ID
 * @property {string} [phone] - Phone number associated with the customer
 * @property {ProductsItem3[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} shipping - Shipping cost associated with the transaction
 * @property {number} tax - Total tax associated with the transaction
 * @property {number} total - Revenue with discounts and coupons added
 */
/**
 * @typedef PageViewed
 * @property {string} [path] -
 * @property {string} [referrer] -
 * @property {string} [search] -
 * @property {string} [title] -
 * @property {string} [url] -
 */
/**
 * @typedef PaymentInfoEntered
 * @property {string} [checkout_id] - Checkout transaction ID
 * @property {string} order_id - Order/transaction ID
 * @property {string} [payment_method] - String representing the payment method chosen
 * @property {string} [shipping_method] - String representing the shipping method chosen
 * @property {string} step - Number representing a step in the checkout process
 */
/**
 * @typedef ProductAdded
 * @property {string} brand - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} name - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [product_id] - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} sku - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef ProductClicked
 * @property {string} brand - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} name - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [product_id] - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef FiltersItem
 * @property {string} [type] - ID of the filter type being used
 * @property {string} [value] - ID of the selection chosen
 */
/**
 * @typedef ProductsItem4
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} coupon - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} url - URL of the product page for product in the list
 * @property {string} variant - Product Variant displayed in the list
 */
/**
 * @typedef SortsItem
 * @property {string} [type] - ID of the sort type being used
 * @property {string} [value] - ID of the selection type being used
 */
/**
 * @typedef ProductListFiltered
 * @property {string} category - Product category being viewed
 * @property {FiltersItem[]} [filters] - Product filters that the customer is using
 * @property {string} list_id - Product list being viewed
 * @property {ProductsItem4[]} products - Products displayed in the product list
 * @property {SortsItem[]} [sorts] - Product sorting the customer is using
 */
/**
 * @typedef ProductsItem5
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} coupon - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} url - URL of the product page for product in the list
 * @property {string} variant - Product Variant displayed in the list
 */
/**
 * @typedef ProductListViewed
 * @property {string} category - Product category being viewed
 * @property {string} list_id - Product list being viewed
 * @property {ProductsItem5[]} products - Products displayed in the product list
 */
/**
 * @typedef ProductRemoved
 * @property {string} brand - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} name - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [product_id] - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} sku - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef ProductReviewed
 * @property {string} product_id - Database ID of the product
 * @property {number} rating - Star rating
 * @property {string} review_body - Content of the review
 * @property {string} review_id - ID of the review posted
 */
/**
 * @typedef ProductViewed
 * @property {string} brand - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} category - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} image_url - Image url of the product
 * @property {string} name - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} sku - Sku of the product
 * @property {string} url - URL of the product page for product
 * @property {string} variant - Variant of the product
 */
/**
 * @typedef PromotionClicked
 * @property {string} promotion_creative - Description of promotion's creative
 * @property {string} promotion_id - ID of the promotion
 * @property {string} promotion_name - Name of the promotion
 * @property {string} [promotion_position] - Position of the promotion
 */
/**
 * @typedef PromotionViewed
 * @property {string} promotion_creative - Description of promotion's creative
 * @property {string} promotion_id - ID of the promotion
 * @property {string} promotion_name - Name of the promotion
 * @property {string} [promotion_position] - Position of the promotion
 */
/**
 * @typedef SignedIn
 * @property {string} [email] - The email of the user
 */
/**
 * @typedef SignedOut
 * @property {string} [email] - The email of the user
 */
/**
 * @typedef SignedUp
 * @property {string} email - User's email address
 * @property {string} [first_name] - User's first name
 * @property {string} [last_name] - User's last name
 * @property {string} [phone] - User's phone number
 * @property {string} [referral_identifier_id] - ID of the referral identifier applied to the user's order
 * @property {string} signup_type - The type of signup ("referral" or "organic")
 * @property {string} [title] - User's salutation
 * @property {string} username - The username of the user
 */
/**
 * @typedef StockRequestCreated
 * @property {string} brand - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} email - Email address added to the form
 * @property {string} [image_url] - Image url of the product
 * @property {string} name - Name of the product
 * @property {number} price - Price of the product
 * @property {string} [product_id] - Database ID of the product
 * @property {string} sku - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef Address
 * @property {string} address1 - Street Address (line 1)
 * @property {string} [address2] - Street Address (line 2)
 * @property {string} city - City
 * @property {string} country - Country
 * @property {string} name - The name of the person for this subscription
 * @property {string} state - State
 * @property {string} zipcode - Zipcode
 */
/**
 * @typedef ProductsItem6
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] -
Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} description - Short description of product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {any[]} [option_values] - Options of the variant included in the subscription
 * @property {number} [position] - Position of the product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {string} [product_sku] - Product SKU
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product variant SKU include in the list
 * @property {string} url - URL of the product page for product in the list
 */
/**
 * @typedef SubscriptionAddressUpdated
 * @property {string} [actionable_date] - Next date when this subscription will be actionable (renewed, expired, etc)
 * @property {Address} address - The shipping address for this subscription
 * @property {string} [end_date] - Subscription will stop auto-renewing after this date
 * @property {number} id - Unique subscription id
 * @property {number} interval_length - Auto renew every X
 * @property {string} interval_units - Units of the interval (day, week, month, year)
 * @property {ProductsItem6[]} products -
 * @property {string} state - The state of the subscription
 */
/**
 * @typedef Address1
 * @property {string} address1 - Street Address (line 1)
 * @property {string} [address2] - Street Address (line 2)
 * @property {string} city - City
 * @property {string} country - Country
 * @property {string} name - The name of the person for this subscription
 * @property {string} state - State
 * @property {string} zipcode - Zipcode
 */
/**
 * @typedef ProductsItem7
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] -
Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {any[]} [option_values] - Options of the variant included in the subscription
 * @property {number} [position] - Position of the product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {string} [product_sku] - Product SKU
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product variant SKU include in the list
 * @property {string} url - URL of the product page for product in the list
 */
/**
 * @typedef SubscriptionAutoRenewed
 * @property {string} [actionable_date] - Next date when this subscription will be actionable (renewed, expired, etc)
 * @property {Address1} address - The shipping address for this subscription
 * @property {string} [end_date] - Subscription will stop auto-renewing after this date
 * @property {number} id - Unique subscription id
 * @property {number} interval_length - Auto renew every X
 * @property {string} interval_units - Units of the interval (day, week, month, year)
 * @property {ProductsItem7[]} products -
 * @property {string} state - The state of the subscription
 */
/**
 * @typedef Address2
 * @property {string} address1 - Street Address (line 1)
 * @property {string} [address2] - Street Address (line 2)
 * @property {string} city - City
 * @property {string} country - Country
 * @property {string} name - The name of the person for this subscription
 * @property {string} state - State
 * @property {string} zipcode - Zipcode
 */
/**
 * @typedef ProductsItem8
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] -
Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {any[]} [option_values] - Options of the variant included in the subscription
 * @property {number} [position] - Position of the product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {string} [product_sku] - Product SKU
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product variant SKU include in the list
 * @property {string} url - URL of the product page for product in the list
 */
/**
 * @typedef SubscriptionCancelled
 * @property {string} [actionable_date] - Next date when this subscription will be actionable (renewed, expired, etc)
 * @property {Address2} address - The shipping address for this subscription
 * @property {string} [end_date] - Subscription will stop auto-renewing after this date
 * @property {number} id - Unique subscription id
 * @property {number} interval_length - Auto renew every X
 * @property {string} interval_units - Units of the interval (day, week, month, year)
 * @property {ProductsItem8[]} products -
 * @property {string} state - The state of the subscription
 */
/**
 * @typedef Address3
 * @property {string} address1 - Street Address (line 1)
 * @property {string} [address2] - Street Address (line 2)
 * @property {string} city - City
 * @property {string} country - Country
 * @property {string} name - The name of the person for this subscription
 * @property {string} state - State
 * @property {string} zipcode - Zipcode
 */
/**
 * @typedef ProductsItem9
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] -
Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {any[]} [option_values] - Options of the variant included in the subscription
 * @property {number} [position] - Position of the product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {string} [product_sku] - Product SKU
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product variant SKU include in the list
 * @property {string} url - URL of the product page for product in the list
 */
/**
 * @typedef SubscriptionCreated
 * @property {string} [actionable_date] - Next date when this subscription will be actionable (renewed, expired, etc)
 * @property {Address3} address - The shipping address for this subscription
 * @property {string} [end_date] - Subscription will stop auto-renewing after this date
 * @property {number} id - Unique subscription id
 * @property {number} interval_length - Auto renew every X
 * @property {string} interval_units - Units of the interval (day, week, month, year)
 * @property {ProductsItem9[]} products -
 * @property {string} state - The state of the subscription
 */
/**
 * @typedef Address4
 * @property {string} address1 - Street Address (line 1)
 * @property {string} [address2] - Street Address (line 2)
 * @property {string} city - City
 * @property {string} country - Country
 * @property {string} name - The name of the person for this subscription
 * @property {string} state - State
 * @property {string} zipcode - Zipcode
 */
/**
 * @typedef ProductsItem10
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] -
Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {any[]} [option_values] - Options of the variant included in the subscription
 * @property {number} [position] - Position of the product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {string} [product_sku] - Product SKU
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product variant SKU include in the list
 * @property {string} url - URL of the product page for product in the list
 */
/**
 * @typedef SubscriptionExpired
 * @property {string} [actionable_date] - Next date when this subscription will be actionable (renewed, expired, etc)
 * @property {Address4} address - The shipping address for this subscription
 * @property {string} [end_date] - Subscription will stop auto-renewing after this date
 * @property {number} id - Unique subscription id
 * @property {number} interval_length - Auto renew every X
 * @property {string} interval_units - Units of the interval (day, week, month, year)
 * @property {ProductsItem10[]} products -
 * @property {string} state - The state of the subscription
 */
/**
 * @typedef Address5
 * @property {string} address1 - Street Address (line 1)
 * @property {string} [address2] - Street Address (line 2)
 * @property {string} city - City
 * @property {string} country - Country
 * @property {string} name - The name of the person for this subscription
 * @property {string} state - State
 * @property {string} zipcode - Zipcode
 */
/**
 * @typedef ProductsItem11
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] -
Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {any[]} [option_values] - Options of the variant included in the subscription
 * @property {number} [position] - Position of the product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {string} [product_sku] - Product SKU
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product variant SKU include in the list
 * @property {string} url - URL of the product page for product in the list
 */
/**
 * @typedef SubscriptionOrderSkipped
 * @property {string} [actionable_date] - Next date when this subscription will be actionable (renewed, expired, etc)
 * @property {Address5} address - The shipping address for this subscription
 * @property {string} [end_date] - Subscription will stop auto-renewing after this date
 * @property {number} id - Unique subscription id
 * @property {number} interval_length - Auto renew every X
 * @property {string} interval_units - Units of the interval (day, week, month, year)
 * @property {ProductsItem11[]} products -
 * @property {string} state - The state of the subscription
 */
/**
 * @typedef Address6
 * @property {string} address1 - Street Address (line 1)
 * @property {string} [address2] - Street Address (line 2)
 * @property {string} city - City
 * @property {string} country - Country
 * @property {string} name - The name of the person for this subscription
 * @property {string} state - State
 * @property {string} zipcode - Zipcode
 */
/**
 * @typedef ProductsItem12
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] -
Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {any[]} [option_values] - Options of the variant included in the subscription
 * @property {number} [position] - Position of the product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {string} [product_sku] - Product SKU
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product variant SKU include in the list
 * @property {string} url - URL of the product page for product in the list
 */
/**
 * @typedef SubscriptionPaused
 * @property {string} [actionable_date] - Next date when this subscription will be actionable (renewed, expired, etc)
 * @property {Address6} address - The shipping address for this subscription
 * @property {string} [end_date] - Subscription will stop auto-renewing after this date
 * @property {number} id - Unique subscription id
 * @property {number} interval_length - Auto renew every X
 * @property {string} interval_units - Units of the interval (day, week, month, year)
 * @property {ProductsItem12[]} products -
 * @property {string} state - The state of the subscription
 */
/**
 * @typedef Address7
 * @property {string} address1 - Street Address (line 1)
 * @property {string} [address2] - Street Address (line 2)
 * @property {string} city - City
 * @property {string} country - Country
 * @property {string} name - The name of the person for this subscription
 * @property {string} state - State
 * @property {string} zipcode - Zipcode
 */
/**
 * @typedef ProductsItem13
 * @property {string} brand - Product Brand displayed in the list
 * @property {string} [category] -
Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {any[]} [option_values] - Options of the variant included in the subscription
 * @property {number} [position] - Position of the product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {string} [product_sku] - Product SKU
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} sku - Product variant SKU include in the list
 * @property {string} url - URL of the product page for product in the list
 */
/**
 * @typedef SubscriptionResumed
 * @property {string} [actionable_date] - Next date when this subscription will be actionable (renewed, expired, etc)
 * @property {Address7} address - The shipping address for this subscription
 * @property {string} [end_date] - Subscription will stop auto-renewing after this date
 * @property {number} id - Unique subscription id
 * @property {number} interval_length - Auto renew every X
 * @property {string} interval_units - Units of the interval (day, week, month, year)
 * @property {ProductsItem13[]} products -
 * @property {string} state - The state of the subscription
 */
/**
 * @typedef Unsubscribed
 * @property {string} [campaign_id] - An id used to identify a campaign
 * @property {string} [campaign_name] - A name used to identify a campaign
 * @property {string} email_id - An ID used to identify the email.
 * @property {string} [email_subject] - The email’s subject line.
 * @property {string} [list_id] - An optional id used to identify a list
 * @property {string} [list_name] - An optional name used to identify a list
 */
/**
 * New account is created
 *
 * @param {AccountCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountCreated(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Account Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Visitor signs up for information on the Ambassador program
 *
 * @param {AmbassadorSignupCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ambassadorSignupCreated(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Ambassador Signup Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed their shopping cart
 *
 * @param {CartViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartViewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Cart Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User abandoned the checkout process
 *
 * @param {CheckoutAbandoned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutAbandoned(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Abandoned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 *
Fired when a customer visits the checkout#success and is mostly used for conversion campaigns
 *
 * @param {CheckoutCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutCompleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User initiated the order process (a transaction is created)
 *
 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed a checkout step
 *
 * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepCompleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a checkout step
 *
 * @param {CheckoutStepViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepViewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked on a Collection
 *
 * @param {CollectionClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function collectionClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Collection Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Coupon was applied on a user’s shopping cart or order
 *
 * @param {CouponApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponApplied(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Coupon was denied from a user’s shopping cart or order
 *
 * @param {CouponDenied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponDenied(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Denied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User entered a coupon on a shopping cart or order
 *
 * @param {CouponEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponEntered(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a coupon from a cart or order
 *
 * @param {CouponRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponRemoved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be sent when an email tool receives notice from an email server that an email is undeliverable.
 *
 * @param {EmailBounced} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailBounced(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Email Bounced',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Email entered in a form on the website
 *
 * @param {EmailCaptured} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailCaptured(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Email Captured',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be fired when the receiving mail server confirms receipt of an email.
 *
 * @param {EmailDelivered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailDelivered(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Email Delivered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be fired when the recipient clicks on a link in the email’s body.
 *
 * @param {EmailLinkClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailLinkClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Email Link Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be fired when a recipient marks an email as spam.
 *
 * @param {EmailMarkedAsSpam} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailMarkedAsSpam(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Email Marked As Spam',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be fired when the recipient opens the email.
 *
 * @param {EmailOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailOpened(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Email Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Customer invites another customer (Referral)
 *
 * @param {InviteSent} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteSent(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Invite Sent',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Item back in stock
 *
 * @param {ItemRestocked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemRestocked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Item Restocked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Registrant starts to sign in to arfa Passport
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginStarted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Login Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed the order
 *
 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCompleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Order Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Page Viewed' track call.
 *
 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added payment information
 *
 * @param {PaymentInfoEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentInfoEntered(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Info Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added a product to their shopping cart
 *
 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAdded(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Product Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked on a product
 *
 * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Product Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User filtered a product list or category
 *
 * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListFiltered(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Product List Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a product list or category
 *
 * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListViewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Product List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a product from their shopping cart
 *
 * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemoved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Product Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User reviewed a product
 *
 * @param {ProductReviewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productReviewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Product Reviewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a product details
 *
 * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productViewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Product Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked on promotion
 *
 * @param {PromotionClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Promotion Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed promotion
 *
 * @param {PromotionViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionViewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Promotion Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Movement or activity registered for a given shipment
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function shipmentUpdated(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Shipment Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Registrant signs in to arfa Passport
 *
 * @param {SignedIn} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedIn(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track('Signed In', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Registrant signs out from arfa Passport
 *
 * @param {SignedOut} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedOut(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track('Signed Out', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Visitor signs up for an account, becoming a registrant
 *
 * @param {SignedUp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedUp(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track('Signed Up', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * A new stock request has been created
 *
 * @param {StockRequestCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function stockRequestCreated(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Stock Request Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Address associated with subscription is updated
 *
 * @param {SubscriptionAddressUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionAddressUpdated(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Address Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Subscription passed the most recent order date, but has not passed the end date.
 *
 * @param {SubscriptionAutoRenewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionAutoRenewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Auto Renewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Subscription cancelled by the user, or failure to auto-renew.
 *
 * @param {SubscriptionCancelled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionCancelled(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Subscription created
 *
 * @param {SubscriptionCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionCreated(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Subscription passed the end date, and new orders will no longer be created at the interval specified.
 *
 * @param {SubscriptionExpired} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionExpired(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Expired',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The upcoming order in a subscription is skipped, making the next order.
 *
 * @param {SubscriptionOrderSkipped} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionOrderSkipped(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Order Skipped',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The subscription is paused and the date of processing is indicated on the `actionable_date` property. A `null` date indicates that the subscription is paused indefinitely.
 *
 * @param {SubscriptionPaused} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionPaused(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Paused',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The paused subscription is resumed and the date of processing is indicated on the `actioanable_date` property.
 *
 * @param {SubscriptionResumed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionResumed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Resumed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be fired when the recipient unsubscribes from the email. The unsubscription can either happen for a particular list, or globally from all marketing emails, depending on the tool. Typically you cannot unsubscribe from a transactional email such as a password reset.
 *
 * @param {Unsubscribed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function unsubscribed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Unsubscribed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * New account is created
	 *
	 * @param {AccountCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountCreated,
	/**
	 * Visitor signs up for information on the Ambassador program
	 *
	 * @param {AmbassadorSignupCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ambassadorSignupCreated,
	/**
	 * User viewed their shopping cart
	 *
	 * @param {CartViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cartViewed,
	/**
	 * User abandoned the checkout process
	 *
	 * @param {CheckoutAbandoned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutAbandoned,
	/**
     *
    Fired when a customer visits the checkout#success and is mostly used for conversion campaigns
     *
     * @param {CheckoutCompleted} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
	checkoutCompleted,
	/**
	 * User initiated the order process (a transaction is created)
	 *
	 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStarted,
	/**
	 * User completed a checkout step
	 *
	 * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepCompleted,
	/**
	 * User viewed a checkout step
	 *
	 * @param {CheckoutStepViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepViewed,
	/**
	 * User clicked on a Collection
	 *
	 * @param {CollectionClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	collectionClicked,
	/**
	 * Coupon was applied on a user’s shopping cart or order
	 *
	 * @param {CouponApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponApplied,
	/**
	 * Coupon was denied from a user’s shopping cart or order
	 *
	 * @param {CouponDenied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponDenied,
	/**
	 * User entered a coupon on a shopping cart or order
	 *
	 * @param {CouponEntered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponEntered,
	/**
	 * User removed a coupon from a cart or order
	 *
	 * @param {CouponRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponRemoved,
	/**
	 * This event should be sent when an email tool receives notice from an email server that an email is undeliverable.
	 *
	 * @param {EmailBounced} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailBounced,
	/**
	 * Email entered in a form on the website
	 *
	 * @param {EmailCaptured} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailCaptured,
	/**
	 * This event should be fired when the receiving mail server confirms receipt of an email.
	 *
	 * @param {EmailDelivered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailDelivered,
	/**
	 * This event should be fired when the recipient clicks on a link in the email’s body.
	 *
	 * @param {EmailLinkClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailLinkClicked,
	/**
	 * This event should be fired when a recipient marks an email as spam.
	 *
	 * @param {EmailMarkedAsSpam} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailMarkedAsSpam,
	/**
	 * This event should be fired when the recipient opens the email.
	 *
	 * @param {EmailOpened} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailOpened,
	/**
	 * Customer invites another customer (Referral)
	 *
	 * @param {InviteSent} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteSent,
	/**
	 * Item back in stock
	 *
	 * @param {ItemRestocked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemRestocked,
	/**
	 * Registrant starts to sign in to arfa Passport
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loginStarted,
	/**
	 * User completed the order
	 *
	 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCompleted,
	/**
	 * Fires a 'Page Viewed' track call.
	 *
	 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * User added payment information
	 *
	 * @param {PaymentInfoEntered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentInfoEntered,
	/**
	 * User added a product to their shopping cart
	 *
	 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productAdded,
	/**
	 * User clicked on a product
	 *
	 * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productClicked,
	/**
	 * User filtered a product list or category
	 *
	 * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListFiltered,
	/**
	 * User viewed a product list or category
	 *
	 * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListViewed,
	/**
	 * User removed a product from their shopping cart
	 *
	 * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productRemoved,
	/**
	 * User reviewed a product
	 *
	 * @param {ProductReviewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productReviewed,
	/**
	 * User viewed a product details
	 *
	 * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productViewed,
	/**
	 * User clicked on promotion
	 *
	 * @param {PromotionClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	promotionClicked,
	/**
	 * User viewed promotion
	 *
	 * @param {PromotionViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	promotionViewed,
	/**
	 * Movement or activity registered for a given shipment
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	shipmentUpdated,
	/**
	 * Registrant signs in to arfa Passport
	 *
	 * @param {SignedIn} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedIn,
	/**
	 * Registrant signs out from arfa Passport
	 *
	 * @param {SignedOut} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedOut,
	/**
	 * Visitor signs up for an account, becoming a registrant
	 *
	 * @param {SignedUp} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedUp,
	/**
	 * A new stock request has been created
	 *
	 * @param {StockRequestCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	stockRequestCreated,
	/**
	 * Address associated with subscription is updated
	 *
	 * @param {SubscriptionAddressUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionAddressUpdated,
	/**
	 * Subscription passed the most recent order date, but has not passed the end date.
	 *
	 * @param {SubscriptionAutoRenewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionAutoRenewed,
	/**
	 * Subscription cancelled by the user, or failure to auto-renew.
	 *
	 * @param {SubscriptionCancelled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionCancelled,
	/**
	 * Subscription created
	 *
	 * @param {SubscriptionCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionCreated,
	/**
	 * Subscription passed the end date, and new orders will no longer be created at the interval specified.
	 *
	 * @param {SubscriptionExpired} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionExpired,
	/**
	 * The upcoming order in a subscription is skipped, making the next order.
	 *
	 * @param {SubscriptionOrderSkipped} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionOrderSkipped,
	/**
	 * The subscription is paused and the date of processing is indicated on the `actionable_date` property. A `null` date indicates that the subscription is paused indefinitely.
	 *
	 * @param {SubscriptionPaused} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionPaused,
	/**
	 * The paused subscription is resumed and the date of processing is indicated on the `actioanable_date` property.
	 *
	 * @param {SubscriptionResumed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionResumed,
	/**
	 * This event should be fired when the recipient unsubscribes from the email. The unsubscription can either happen for a particular list, or globally from all marketing emails, depending on the tool. Typically you cannot unsubscribe from a transactional email such as a password reset.
	 *
	 * @param {Unsubscribed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	unsubscribed,
}
export default new Proxy(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method]
		}
		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/chord-commerce/protocols/tracking-plans/rs_1to7djTluQPx29q3akmipyx1ipV`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
