export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_RESPONSE = "AUTH_LOGIN_RESPONSE";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";
export const AUTH_LOGOUT_REQUEST = "AUTH_LOGOUT_REQUEST";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_ERROR = "AUTH_LOGOUT_ERROR";
export const AUTH_IDENTIFY_REQUEST = "AUTH_IDENTIFY_REQUEST";
export const AUTH_IDENTIFY_SUCCESS = "AUTH_IDENTIFY_SUCCESS";
export const AUTH_IDENTIFY_ERROR = "AUTH_IDENTIFY_ERROR";
export const CLEAR_SESSION = "CLEAR_SESSION";
export const LOAD_CART_REQUEST = "LOAD_CART_REQUEST";
export const LOAD_CART_SUCCESS = "LOAD_CART_SUCCESS";
export const LOAD_CART_ERROR = "LOAD_CART_ERROR";
export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_ERROR = "ADD_TO_CART_ERROR";
export const MODIFY_CART_QUANTITY_REQUEST = "MODIFY_CART_QUANTITY_REQUEST";
export const MODIFY_CART_QUANTITY_SUCCESS = "MODIFY_CART_QUANTITY_SUCCESS";
export const MODIFY_CART_QUANTITY_ERROR = "MODIFY_CART_QUANTITY_ERROR";
export const MODIFY_CART_GIFT_CARDS_REQUEST = "MODIFY_CART_GIFT_CARDS_REQUEST";
export const MODIFY_CART_GIFT_CARDS_SUCCESS = "MODIFY_CART_GIFT_CARDS_SUCCESS";
export const MODIFY_CART_GIFT_CARDS_ERROR = "MODIFY_CART_GIFT_CARDS_ERROR";
export const REMOVE_FROM_CART_REQUEST = "REMOVE_FROM_CART_REQUEST";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_ERROR = "REMOVE_FROM_CART_ERROR";
export const FORGET_CART = "FORGET_CART";
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_ERROR = "CHECKOUT_ERROR";
export const FINALIZE_CHECKOUT_REQUEST = "FINALIZE_CHECKOUT_REQUEST";
export const FINALIZE_CHECKOUT_SUCCESS = "FINALIZE_CHECKOUT_SUCCESS";
export const FINALIZE_CHECKOUT_ERROR = "FINALIZE_CHECKOUT_ERROR";
export const ADD_PROMO_CODE_REQUEST = "ADD_PROMO_CODE_REQUEST";
export const ADD_PROMO_CODE_SUCCESS = "ADD_PROMO_CODE_SUCCESS";
export const ADD_PROMO_CODE_ERROR = "ADD_PROMO_CODE_ERROR";
export const REMOVE_PROMO_CODE_REQUEST = "REMOVE_PROMO_CODE_REQUEST";
export const REMOVE_PROMO_CODE_SUCCESS = "REMOVE_PROMO_CODE_SUCCESS";
export const REMOVE_PROMO_CODE_ERROR = "REMOVE_PROMO_CODE_ERROR";
export const TRACK_CLICK_PRODUCT = "TRACK_CLICK_PRODUCT";
export const TRACK_CLICK_COLLECTION = "TRACK_CLICK_COLLECTION";
export const TRACK_CREATE_STOCK_REQUEST = "TRACK_CREATE_STOCK_REQUEST";
export const TRACK_EMAIL_CAPTURED = "TRACK_EMAIL_CAPTURED";
export const TRACK_VIEW_CART = "TRACK_VIEW_CART";
export const INITIALIZE_STATE_FROM_STORAGE = "INITIALIZE_STATE_FROM_STORAGE";
export const MODIFY_CART_REQUEST = "MODIFY_CART_REQUEST";
export const MODIFY_CART_SUCCESS = "MODIFY_CART_SUCCESS";
export const MODIFY_CART_ERROR = "MODIFY_CART_ERROR";
export const LOAD_USER_REFERRAL_IDENTIFIER_REQUEST =
  "LOAD_USER_REFERRAL_IDENTIFIER_REQUEST";
export const LOAD_USER_REFERRAL_IDENTIFIER_SUCCESS =
  "LOAD_USER_REFERRAL_IDENTIFIER_SUCCESS";
export const LOAD_USER_REFERRAL_IDENTIFIER_ERROR =
  "LOAD_USER_REFERRAL_IDENTIFIER_ERROR";
export const SUBSCRIBE_PRODUCT_ERROR = "SUBSCRIBE_PRODUCT_ERROR";
export const SUBSCRIBE_PRODUCT_SUCCESS = "SUBSCRIBE_PRODUCT_SUCCESS";
export const SUBSCRIBE_PRODUCT_REQUEST = "SUBSCRIBE_PRODUCT_REQUEST";
export const LOAD_SUBSCRIPTION_ERROR = "LOAD_SUBSCRIPTION_ERROR";
export const LOAD_SUBSCRIPTION_SUCCESS = "LOAD_SUBSCRIPTION_SUCCESS";
export const LOAD_SUBSCRIPTION_REQUEST = "LOAD_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_ERROR = "CANCEL_SUBSCRIPTION_ERROR";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const SKIP_SUBSCRIPTION_ERROR = "SKIP_SUBSCRIPTION_ERROR";
export const SKIP_SUBSCRIPTION_SUCCESS = "SKIP_SUBSCRIPTION_SUCCESS";
export const SKIP_SUBSCRIPTION_REQUEST = "SKIP_SUBSCRIPTION_REQUEST";
export const LOAD_USER_SUBSCRIPTIONS_ERROR = "LOAD_USER_SUBSCRIPTIONS_ERROR";
export const LOAD_USER_SUBSCRIPTIONS_SUCCESS =
  "LOAD_USER_SUBSCRIPTIONS_SUCCESS";
export const LOAD_USER_SUBSCRIPTIONS_REQUEST =
  "LOAD_USER_SUBSCRIPTIONS_REQUEST";
export const UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_ERROR = "UPDATE_SUBSCRIPTION_ERROR";
export const RESUME_SUBSCRIPTION_REQUEST = "RESUME_SUBSCRIPTION_REQUEST";
export const RESUME_SUBSCRIPTION_SUCCESS = "RESUME_SUBSCRIPTION_SUCCESS";
export const RESUME_SUBSCRIPTION_ERROR = "RESUME_SUBSCRIPTION_ERROR";
export const PAUSE_SUBSCRIPTION_REQUEST = "PAUSE_SUBSCRIPTION_REQUEST";
export const PAUSE_SUBSCRIPTION_SUCCESS = "PAUSE_SUBSCRIPTION_SUCCESS";
export const PAUSE_SUBSCRIPTION_ERROR = "PAUSE_SUBSCRIPTION_ERROR";
export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_ERROR = "LOAD_USER_ERROR";
export const MODIFY_USER_REQUEST = "MODIFY_USER_REQUEST";
export const MODIFY_USER_SUCCESS = "MODIFY_USER_SUCCESS";
export const MODIFY_USER_ERROR = "MODIFY_USER_ERROR";
export const ASSOCIATE_CART_WITH_USER_REQUEST =
  "ASSOCIATE_CART_WITH_USER_REQUEST";
export const ASSOCIATE_CART_WITH_USER_SUCCESS =
  "ASSOCIATE_CART_WITH_USER_SUCCESS";
export const ASSOCIATE_CART_WITH_USER_ERROR = "ASSOCIATE_CART_WITH_USER_ERROR";
export const LOAD_CATALOG_REQUEST = "LOAD_CATALOG_REQUEST";
export const LOAD_CATALOG_SUCCESS = "LOAD_CATALOG_SUCCESS";
export const LOAD_CATALOG_ERROR = "LOAD_CATALOG_ERROR";
export const CREATE_STOCK_REQUEST_REQUEST = "CREATE_STOCK_REQUEST_REQUEST";
export const CREATE_STOCK_REQUEST_SUCCESS = "CREATE_STOCK_REQUEST_SUCCESS";
export const CREATE_STOCK_REQUEST_ERROR = "CREATE_STOCK_REQUEST_ERROR";

export const LOAD_ACCOUNT_CREDIT_REQUEST = "LOAD_ACCOUNT_CREDIT_REQUEST";
export const LOAD_ACCOUNT_CREDIT_SUCCESS = "LOAD_ACCOUNT_CREDIT_SUCCESS";
export const LOAD_ACCOUNT_CREDIT_ERROR = "LOAD_ACCOUNT_CREDIT_ERROR";

export const LOAD_ADDRESS_BOOK_REQUEST = "LOAD_ADDRESS_BOOK_REQUEST";
export const LOAD_ADDRESS_BOOK_SUCCESS = "LOAD_ADDRESS_BOOK_SUCCESS";
export const LOAD_ADDRESS_BOOK_ERROR = "LOAD_ADDRESS_BOOK_ERROR";

export const LOAD_ORDER_REQUEST = "LOAD_ORDER_REQUEST";
export const LOAD_ORDER_SUCCESS = "LOAD_ORDER_SUCCESS";
export const LOAD_ORDER_ERROR = "LOAD_ORDER_ERROR";

export const LOAD_ORDERS_REQUEST = "LOAD_ORDERS_REQUEST";
export const LOAD_ORDERS_SUCCESS = "LOAD_ORDERS_SUCCESS";
export const LOAD_ORDERS_ERROR = "LOAD_ORDERS_ERROR";

export const UPDATE_ADDRESS_BOOK_REQUEST = "UPDATE_ADDRESS_BOOK_REQUEST";
export const UPDATE_ADDRESS_BOOK_SUCCESS = "UPDATE_ADDRESS_BOOK_SUCCESS";
export const UPDATE_ADDRESS_BOOK_ERROR = "UPDATE_ADDRESS_BOOK_ERROR";

export const REMOVE_FROM_ADDRESS_BOOK_REQUEST =
  "REMOVE_FROM_ADDRESS_BOOK_REQUEST";
export const REMOVE_FROM_ADDRESS_BOOK_SUCCESS =
  "REMOVE_FROM_ADDRESS_BOOK_SUCCESS";
export const REMOVE_FROM_ADDRESS_BOOK_ERROR = "REMOVE_FROM_ADDRESS_BOOK_ERROR";

const status = {
  Idle: "idle",
  Initial: "initial",
  Failed: "failed",
  Loading: "loading",
  Succeeded: "succeeded",
};

export const AuthStatus = status;
export const UserStatus = status;
