import { useSelector, useDispatch } from "react-redux";
import { useMemo } from "react";
import { bindActionCreators } from "redux";
import * as actions from "../../redux/actions/auth";

export function useAuth() {
  const token = useSelector((state) => state.auth.token);
  const isFetching = useSelector((state) => state.auth.isFetching);
  const isLoggedIn = !!token;

  const dispatch = useDispatch();

  const getToken = useMemo(
    () => bindActionCreators(actions.getToken, dispatch),
    [dispatch],
  );

  const handleCallback = useMemo(
    () => bindActionCreators(actions.handleCallback, dispatch),
    [dispatch],
  );

  const login = useMemo(
    () => bindActionCreators(actions.login, dispatch),
    [dispatch],
  );

  const logout = useMemo(
    () => bindActionCreators(actions.logout, dispatch),
    [dispatch],
  );

  return {
    getToken,
    handleCallback,
    login,
    logout,
    isLoggedIn,
    isFetching,
    token,
  };
}
