import getAllProducts from '~/redux/selectors/catalog/get-all-products'

export const findProductBySlug = (state, props) => {
  const allProducts = getAllProducts(state)

  const results = allProducts.find(product => {
    return Object.keys(props).every(key => product[key] === props[key])
  })

  return results
}

export const findProductBySku = (state, { sku }) => {
  const allProducts = getAllProducts(state)

  const results = allProducts.reduce((acc, p) => {
    const variant = p.variants ? p.variants.find(v => v.sku === sku) : null

    if (variant) {
      return acc.concat({ ...variant, name: p.name, slug: p.slug })
    } else {
      return acc
    }
  }, [])

  return results.length > 0 ? results[0] : null
}

export const findProductOrKit = (state, { slug, sku }) => {
  const isKit = sku.includes('|')

  const productOrKit = isKit
    ? findProductBySlugOrDefault(state, { slug: slug })
    : findProductBySkuOrDefault(state, { sku: sku })
  return productOrKit
}

export const findProductBySlugOrDefault = (state, props) =>
  findProductBySlug(state, props) || props

export const findProductBySkuOrDefault = (state, props) =>
  findProductBySku(state, props) || props

export const findProductOrKitOrDefault = (state, props) =>
  findProductOrKit(state, props) || props
