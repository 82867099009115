import { createSelector } from 'reselect'

const getCatalogs = state => state.catalog.data

const getAllProducts = createSelector([getCatalogs], collections => {
  const products = collections.reduce(
    (acc, collection = {}) =>
      collection.products ? acc.concat(collection.products) : acc,
    []
  )

  const kits = collections.reduce(
    (acc, collection = {}) =>
      collection.kits ? acc.concat(collection.kits) : acc,
    []
  )

  return products.concat(kits)
})

export default getAllProducts
