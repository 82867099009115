import {
  REMOVE_FROM_CART_ERROR,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART_SUCCESS
} from '~/redux/actions/types'
import { getOrLoadCart } from './get-or-load-cart'

export const removeFromCartRequest = lineItemId => {
  return {
    type: REMOVE_FROM_CART_REQUEST,
    lineItemId: lineItemId
  }
}

export const removeFromCartSuccess = (cart, variant, quantity) => {
  return {
    type: REMOVE_FROM_CART_SUCCESS,
    data: cart,
    variant,
    quantity
  }
}

export const removeFromCartError = (error, meta = {}) => {
  return {
    type: REMOVE_FROM_CART_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const removeFromCart =
  lineItemId =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(removeFromCartRequest(lineItemId))

      const { data: cart } = await getOrLoadCart(dispatch, getState)

      await api.removeFromCart(cart, lineItemId)
      const updatedCart = await api.fetchCart(cart.number)

      const removedLineItem = getLineItemById(cart, lineItemId)
      const variant = removedLineItem.variant
      const quantity = -removedLineItem.quantity
      dispatch(removeFromCartSuccess(updatedCart, variant, quantity))
    } catch (error) {
      dispatch(removeFromCartError(error, { lineItemId }))
      throw error
    }
  }

const getLineItemById = (cart, id) => {
  return cart.lineItems.find(lineItem => lineItem.id === id)
}
