import { PropsWithChildren, useEffect, useState } from "react";
import { EmailOptInModal } from "@bluebottlecoffee/design-system";
import { BaseProps } from "../lib/sanity/shared";
import {
  handleEmailOptIn,
  handleEmailOptInDismiss,
  shouldDisplayEmailOptIn,
} from "../lib/component-utils/email-opt-in";
import { toEmailOptInProps } from "../lib/transformers/emailOptIn";
import { useAuth } from "../lib/chord/packages/web/src/hooks/actions/use-auth";

export type ConditionalEmailOptInProps = PropsWithChildren<{
  pageProps: BaseProps;
}>;

export const ConditionalEmailOptInModal = ({
  pageProps,
}: ConditionalEmailOptInProps) => {
  const { emailOptIn, lang, region } = pageProps;
  const { isLoggedIn } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  function handleDismiss() {
    // User dismissed the modal, don't show again
    handleEmailOptInDismiss();
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      // Display the modal, but only after 30 seconds.
      setIsOpen(shouldDisplayEmailOptIn(isLoggedIn));
    }, 30_000);
    return () => {
      clearTimeout(timer);
    };
  }, [isLoggedIn]);

  return emailOptIn && lang && region && isOpen ? (
    <EmailOptInModal
      {...toEmailOptInProps(emailOptIn, lang, region, (emailAddress: string) =>
        handleEmailOptIn(emailAddress),
      )}
      isOpen={isOpen}
      onDismiss={handleDismiss}
    />
  ) : (
    <></>
  );
};
