import {
  LOAD_ORDERS_REQUEST,
  LOAD_ORDERS_SUCCESS,
  LOAD_ORDERS_ERROR,
} from "../types";

export const loadOrdersRequest = () => ({
  type: LOAD_ORDERS_REQUEST,
});

export const loadOrdersSuccess = (data) => ({
  type: LOAD_ORDERS_SUCCESS,
  payload: {
    data,
  },
});

export const loadOrdersError = (error) => ({
  type: LOAD_ORDERS_ERROR,
  payload: error,
  error: true,
});

export const loadOrders =
  () =>
  async (dispatch, _getState, { api }) => {
    dispatch(loadOrdersRequest());
    try {
      const response = await api.fetchOrders();
      dispatch(loadOrdersSuccess(response));
    } catch (error) {
      dispatch(loadOrdersError(error));
      throw error;
    }
  };
