import { TRACK_CLICK_COLLECTION } from '~/redux/actions/types'

export const trackClickCollection = (title, slug, url) => {
  return {
    type: TRACK_CLICK_COLLECTION,
    title,
    slug,
    url
  }
}
