import {
  LOAD_ORDER_REQUEST,
  LOAD_ORDER_SUCCESS,
  LOAD_ORDER_ERROR,
} from "../types";

export const loadOrderRequest = () => ({
  type: LOAD_ORDER_REQUEST,
});

export const loadOrderSuccess = (data) => ({
  type: LOAD_ORDER_SUCCESS,
  payload: {
    data,
  },
});

export const loadOrderError = (error) => ({
  type: LOAD_ORDER_ERROR,
  payload: error,
  error: true,
});

export const loadOrder =
  (orderNumber) =>
  async (dispatch, _getState, { api }) => {
    dispatch(loadOrderRequest());
    try {
      const response = await api.fetchOrder(orderNumber);
      dispatch(loadOrderSuccess(response));
    } catch (error) {
      dispatch(loadOrderError(error));
      throw error;
    }
  };
