import { EmailOptInModalProps } from "@bluebottlecoffee/design-system/dist/EmailOptInModal/EmailOptInModal";
import { EmailOptIn } from "../sanity-schema";
import { toImageResponsiveProps } from "./image";
import { toLinkProps } from "./link";

export function toEmailOptInProps(
  data: EmailOptIn,
  lang: string,
  region: string,
  onSubmit: (...args: any[]) => any,
): EmailOptInModalProps {
  return {
    emailOptInFormProps: {
      copy: {
        title: data.title[lang],
        heading: data.heading[lang],
        emailPlaceholder: data.emailPlaceholder[lang],
        submitButtonText: data.submitButtonText[lang],
      },
      image: toImageResponsiveProps(data.image, lang),
      footerLink: toLinkProps(data.footerLink, region, lang),
      bgColor: data.bgColor,
      onSubmit,
    },
  };
}
