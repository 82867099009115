import dynamic from "next/dynamic";
import { ConsentManager as ConsentManagerType } from "@segment/consent-manager";
import { FunctionComponent, PropsWithChildren } from "react";
import { privacyPage } from "../lib/link-builders";
import { PageTracker } from "./PageTracker";
import { BaseProps } from "../lib/sanity/shared";

export type ConsentManagerCopyProps = {
  bannerSubContent: string;
  cancelDialogText: string;
  cancelDialogTitle: string;
  continueToUse: string;
  preferencesDialogText: string;
  preferencesDialogTextLine2?: string;
  preferencesDialogTextLine3?: string;
  preferencesDialogTitle: string;
  privacyPolicyLinkText: string;
  privacyPolicyPrefix: string;
  weUseCookies: string;
};

export type ConsentManagerWrapperProps = {
  consentManagerCopy: ConsentManagerCopyProps; // From Sanity
  lang: string;
  region: string;
};

/*
 We dynamically import Segment Consent Manager here due to this 
 issue: https://github.com/segmentio/consent-manager/issues/263
*/
const ConsentManager = dynamic(
  () =>
    import("@segment/consent-manager").then((lib) => lib.ConsentManager as any),
  {
    ssr: false,
  },
) as typeof ConsentManagerType;

const onError = (error) => {
  console.log("ConsentManagerBanner error", error);
};

const shouldRequireConsent = async () => {
  // Code splitting this into a runtime import as it imports a timezone
  // database, which is quite large. By having it in it's own bundle, a second
  // touch from the customer should result in a cache hit, even if the app
  // updated.
  const inEU = (await import("@segment/in-eu")).default;
  return inEU();
};

/**
 * This file integrates Segment's consent-manager to protect our visitors privacy.
 *
 * See https://github.com/segmentio/consent-manager
 */
export const ConsentManagerWrapper: FunctionComponent<
  ConsentManagerWrapperProps
> = ({ region, lang, consentManagerCopy, children }) => {
  const {
    bannerSubContent,
    cancelDialogText,
    cancelDialogTitle,
    continueToUse,
    preferencesDialogText,
    preferencesDialogTextLine2,
    preferencesDialogTextLine3,
    preferencesDialogTitle,
    privacyPolicyLinkText,
    privacyPolicyPrefix,
    weUseCookies,
  } = consentManagerCopy;

  const bannerContent = (
    <span>
      {weUseCookies}
      {continueToUse}
    </span>
  );

  const preferencesDialogContent = (
    <span>
      {weUseCookies} {privacyPolicyPrefix}{" "}
      <a href={privacyPage({ region, lang })}>{privacyPolicyLinkText}</a>{" "}
      {preferencesDialogText}
      {preferencesDialogTextLine2 && (
        <>
          <br />
          <br />
          {preferencesDialogTextLine2}
        </>
      )}
      {preferencesDialogTextLine3 && (
        <>
          <br />
          <br />
          {preferencesDialogTextLine3}
        </>
      )}
    </span>
  );

  const cancelDialogContent = (
    <span>
      {cancelDialogText}
      {continueToUse}
    </span>
  );

  // From OP
  // https://github.com/bluebottlecoffee/bluebottle/blob/main/app/views/shared/analytics/_segment_consent_manager.html.slim
  const initialPreferences = {
    advertising: true,
    functional: true,
    marketingAndAnalytics: true,
  };

  const closeBehavior = "accept" as any;

  return (
    <>
      {children}
      <div>
        <ConsentManager
          writeKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}
          otherWriteKeys={[process.env.NEXT_PUBLIC_SEGMENT_BACKEND_WRITE_KEY]}
          shouldRequireConsent={shouldRequireConsent}
          bannerContent={bannerContent}
          bannerSubContent={bannerSubContent}
          cancelDialogContent={cancelDialogContent}
          cancelDialogTitle={cancelDialogTitle}
          closeBehavior={closeBehavior}
          defaultDestinationBehavior="imply"
          implyConsentOnInteraction={false}
          initialPreferences={initialPreferences}
          preferencesDialogContent={preferencesDialogContent}
          preferencesDialogTitle={preferencesDialogTitle}
          onError={onError}
        />
      </div>
    </>
  );
};

export type ConditionalConsentManagerProps = PropsWithChildren<{
  pageProps: BaseProps;
}>;

export function ConditionalConsentManager({
  children,
  pageProps,
}: ConditionalConsentManagerProps) {
  // `pageProps` have all the info you are sending to the component that's being
  // loaded for the page (url) you're on.
  const { consentManagerCopy, lang, region } = pageProps;

  return consentManagerCopy && lang && region ? (
    <ConsentManagerWrapper
      consentManagerCopy={consentManagerCopy}
      lang={lang}
      region={region}
    >
      <PageTracker>{children}</PageTracker>
    </ConsentManagerWrapper>
  ) : (
    <>{children}</>
  );
}
