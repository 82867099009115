import {
  ADD_PROMO_CODE_ERROR,
  ADD_PROMO_CODE_REQUEST,
  ADD_PROMO_CODE_SUCCESS,
  ADD_TO_CART_SUCCESS,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  CHECKOUT_SUCCESS,
  FINALIZE_CHECKOUT_SUCCESS,
  MODIFY_CART_QUANTITY_SUCCESS,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_PROMO_CODE_SUCCESS,
  TRACK_CLICK_COLLECTION,
  TRACK_CLICK_PRODUCT,
  TRACK_CREATE_STOCK_REQUEST,
  TRACK_EMAIL_CAPTURED,
  TRACK_VIEW_CART,
  CANCEL_SUBSCRIPTION_SUCCESS,
  SKIP_SUBSCRIPTION_SUCCESS,
  RESUME_SUBSCRIPTION_SUCCESS,
  PAUSE_SUBSCRIPTION_SUCCESS,
  CREATE_STOCK_REQUEST_SUCCESS
} from '~/redux/actions/types'
import {
  findProductBySkuOrDefault,
  findProductBySlugOrDefault,
  findProductOrKitOrDefault
} from '~/redux/selectors/product/find-product'
import {
  identifyUser,
  trackCartViewed,
  trackCheckoutCompleted,
  trackCheckoutStarted,
  trackCollectionClicked,
  trackCouponApplied,
  trackCouponDenied,
  trackCouponEntered,
  trackCouponRemoved,
  trackEmailCaptured,
  trackLoginStarted,
  trackProductClicked,
  trackProductInCart,
  trackSignedIn,
  trackSignedOut,
  trackStockRequestCreated,
  trackSubscriptionSkipped,
  trackSubscriptionCancelled,
  trackSubscriptionPaused,
  trackSubscriptionResumed
} from '~/services/analytics'

const analytics = store => next => action => {
  let result = next(action)
  let nextState = store.getState()

  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      trackLoginStarted()
      break

    case AUTH_LOGIN_SUCCESS:
      trackUserSignIn(nextState, action)
      break

    case AUTH_LOGOUT_SUCCESS:
      trackUserSignOut(nextState, action)
      break

    case ADD_PROMO_CODE_REQUEST:
      addPromoCodeRequest(nextState, action)
      break

    case ADD_PROMO_CODE_SUCCESS:
      addPromoCodeSuccess(nextState, action)
      break

    case ADD_PROMO_CODE_ERROR:
      addPromoCodeError(nextState, action)
      break

    case ADD_TO_CART_SUCCESS:
    case MODIFY_CART_QUANTITY_SUCCESS:
    case REMOVE_FROM_CART_SUCCESS:
      trackProductQuantity(nextState, action)
      break

    case TRACK_CLICK_PRODUCT:
      trackClickProduct(nextState, action)
      break

    case TRACK_CLICK_COLLECTION:
      trackClickCollection(nextState, action)
      break

    case CREATE_STOCK_REQUEST_SUCCESS:
    case TRACK_CREATE_STOCK_REQUEST:
      trackCreateStockRequest(nextState, action)
      break

    case TRACK_EMAIL_CAPTURED:
      trackCaptureEmail(nextState, action)
      break

    case TRACK_VIEW_CART:
      trackViewCart(nextState, action)
      break

    case CHECKOUT_SUCCESS:
      trackStartCheckout(nextState, action)
      break

    case FINALIZE_CHECKOUT_SUCCESS:
      trackCompleteCheckout(nextState, action)
      break

    case REMOVE_PROMO_CODE_SUCCESS:
      removePromoCodeSuccess(nextState, action)
      break

    case CANCEL_SUBSCRIPTION_SUCCESS:
      trackSubscriptionCancelled(subscriptionDetails(nextState))
      break

    case SKIP_SUBSCRIPTION_SUCCESS:
      trackSubscriptionSkipped(subscriptionDetails(nextState))
      break

    case RESUME_SUBSCRIPTION_SUCCESS:
      trackSubscriptionResumed(subscriptionDetails(nextState))
      break

    case PAUSE_SUBSCRIPTION_SUCCESS:
      trackSubscriptionPaused(subscriptionDetails(nextState))
      break

    default:
      return nextState
  }

  return result
}

const trackUserSignIn = (state, action) => {
  const { email } = action.payload
  const { userId } = state.cart.data
  identifyUser(userId, { email })
  trackSignedIn({ userId, email })
}

const trackUserSignOut = (state, action) => {
  const { email } = action.payload
  const { userId } = state.cart.data
  trackSignedOut({ userId, email })
}

const addPromoCodeRequest = (state, action) => {
  trackCouponEntered({
    cart: state.cart.data,
    promoCode: action.promoCode
  })
}

const addPromoCodeSuccess = (state, action) => {
  trackCouponApplied({
    cart: state.cart.data,
    promoCode: action.promoCode,
    promoAmount: action.promoAmount
  })
}

const addPromoCodeError = (state, action) => {
  trackCouponDenied({
    cart: state.cart.data,
    promoCode: action.promoCode,
    reason: action.reason
  })
}

const removePromoCodeSuccess = (state, action) => {
  trackCouponRemoved({
    cart: state.cart.data,
    promoCode: action.promoCode,
    promoAmount: action.promoAmount
  })
}

const trackClickProduct = (state, action) => {
  const product = findProductBySlugOrDefault(state, { slug: action.slug })
  trackProductClicked({
    cart: state.cart.data,
    product
  })
}

const trackClickCollection = (_state, action) => {
  trackCollectionClicked({
    title: action.title,
    slug: action.slug,
    url: `${window.location.protocol}//${window.location.hostname}${action.url}`
  })
}

const trackProductQuantity = (state, action) => {
  const product = findProductOrKitOrDefault(state, {
    slug: action.variant.slug,
    sku: action.variant.sku
  })

  trackProductInCart({
    cart: state.cart.data,
    product: { ...product, sku: action.variant.sku },
    quantity: action.quantity
  })
}

const trackCreateStockRequest = (state, action) => {
  const product = findProductBySkuOrDefault(state, { sku: action.sku })
  trackStockRequestCreated({
    email: action.email,
    cart: state.cart.data,
    product
  })
}

const trackCaptureEmail = (_state, action) => {
  trackEmailCaptured({
    name: action.name,
    email: action.email,
    webPlacement: action.webPlacement
  })
}

const trackViewCart = (state, _action) => {
  const cart = state.cart.data
  trackCartViewed({
    cartNumber: cart.number,
    lineItems: lineItemsDetails(state)
  })
}

const trackStartCheckout = (state, _action) => {
  const cart = state.cart.data
  trackCheckoutStarted({
    cart,
    lineItems: lineItemsDetails(state)
  })
}

const trackCompleteCheckout = (state, action) => {
  identifyUser(action.cart.email)
  trackCheckoutCompleted({ cart: action.cart })
}

const subscriptionDetails = state => {
  const subscription = state.subscription.data
  return {
    ...subscription,
    lineItems: subscription.lineItems.map(lineItem => {
      const product = findProductOrKitOrDefault(state, {
        slug: lineItem.productSlug,
        sku: lineItem.sku
      })
      const quantity = lineItem.quantity
      const path = lineItem.sku.includes('|') ? 'bundles' : 'products'
      const url = `${window.location.protocol}//${window.location.hostname}/${path}/${lineItem.productSlug}`

      return { ...product, sku: lineItem.sku, quantity, url }
    })
  }
}

const lineItemsDetails = state => {
  const cart = state.cart.data
  return cart.lineItems.map(lineItem => {
    const product = findProductOrKitOrDefault(state, {
      slug: lineItem.variant.slug,
      sku: lineItem.variant.sku
    })
    const quantity = lineItem.quantity
    const path = lineItem.variant.sku.includes('|') ? 'bundles' : 'products'
    const url = `${window.location.protocol}//${window.location.hostname}/${path}/${lineItem.variant.slug}`

    return { ...product, sku: lineItem.variant.sku, quantity, url }
  })
}

export default analytics
