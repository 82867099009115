import {
  SlideOverDialogContextProvider,
  ScreenSizeContextProvider,
} from "@bluebottlecoffee/design-system";
import "@bluebottlecoffee/design-system/dist/index.css";
import "../styles/globals.css";
import Honeybadger from "@honeybadger-io/js";
import Script from "next/script";
import { wrapper } from "../lib/chord/packages/web/src/redux/store";
import { ConditionalConsentManager } from "../components/ConsentManagerWrapper";
import { ConditionalEmailOptInModal } from "../components/ConditionalEmailOptInModal";

Honeybadger.configure({
  apiKey: process.env.NEXT_PUBLIC_HONEYBADGER_API_KEY,
  revision: process.env.NEXT_PUBLIC_HONEYBADGER_REVISION,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || "development",
  projectRoot: "webpack://_N_E/",
  // uncomment to report errors in development
  // reportData: true,
});

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Script
        id="show-banner"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};;analytics.SNIPPET_VERSION="4.13.2";
          analytics.page();
          }}();`,
        }}
      />
      <div id="modal-root" />
      <SlideOverDialogContextProvider>
        <ScreenSizeContextProvider>
          <ConditionalEmailOptInModal pageProps={pageProps} />
          <ConditionalConsentManager pageProps={pageProps}>
            <Component {...pageProps} />
          </ConditionalConsentManager>
        </ScreenSizeContextProvider>
      </SlideOverDialogContextProvider>
    </>
  );
}

export default wrapper.withRedux(MyApp);
