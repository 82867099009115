import { TRACK_EMAIL_CAPTURED } from '~/redux/actions/types'

export const trackEmailCaptured = (
  email,
  name = undefined,
  webPlacement = undefined
) => ({
  type: TRACK_EMAIL_CAPTURED,
  email,
  name,
  webPlacement
})