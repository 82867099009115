import {
  LOAD_CATALOG_REQUEST,
  LOAD_CATALOG_SUCCESS,
  LOAD_CATALOG_ERROR
} from '~/redux/actions/types'

import initialState from '~/redux/store/initial-state'

const catalog = (state = initialState.catalog, action) => {
  switch (action.type) {
    case LOAD_CATALOG_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      }

    case LOAD_CATALOG_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        error: null
      }
    }

    case LOAD_CATALOG_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false
      }

    default:
      return state
  }
}

export default catalog
