import {
  AUTH_LOGOUT_SUCCESS,
  LOAD_SUBSCRIPTION_REQUEST,
  LOAD_SUBSCRIPTION_SUCCESS,
  LOAD_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
  SKIP_SUBSCRIPTION_REQUEST,
  SKIP_SUBSCRIPTION_SUCCESS,
  SKIP_SUBSCRIPTION_ERROR,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_ERROR,
  RESUME_SUBSCRIPTION_REQUEST,
  RESUME_SUBSCRIPTION_SUCCESS,
  RESUME_SUBSCRIPTION_ERROR,
  PAUSE_SUBSCRIPTION_REQUEST,
  PAUSE_SUBSCRIPTION_SUCCESS,
  PAUSE_SUBSCRIPTION_ERROR
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const subscription = (state = initialState.subscription, action) => {
  switch (action.type) {
    case AUTH_LOGOUT_SUCCESS:
      return {
        data: {},
        isFetching: false,
        isLoaded: false
      }

    case LOAD_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoaded: false
      }

    case CANCEL_SUBSCRIPTION_REQUEST:
    case SKIP_SUBSCRIPTION_REQUEST:
    case UPDATE_SUBSCRIPTION_REQUEST:
    case RESUME_SUBSCRIPTION_REQUEST:
    case PAUSE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoaded: true
      }

    case LOAD_SUBSCRIPTION_SUCCESS:
    case CANCEL_SUBSCRIPTION_SUCCESS:
    case SKIP_SUBSCRIPTION_SUCCESS:
    case UPDATE_SUBSCRIPTION_SUCCESS:
    case RESUME_SUBSCRIPTION_SUCCESS:
    case PAUSE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        data: action.payload.data
      }

    case LOAD_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isFetching: false,
        isLoaded: false
      }

    case CANCEL_SUBSCRIPTION_ERROR:
    case SKIP_SUBSCRIPTION_ERROR:
    case UPDATE_SUBSCRIPTION_ERROR:
    case RESUME_SUBSCRIPTION_ERROR:
    case PAUSE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isFetching: false
      }

    default:
      return state
  }
}

export default subscription
