import axios from "axios";
import humps from "humps";
import ApiError from "./error";
import { getAnonymousId } from "../analytics";
import { getToken } from "../../redux/actions";

export const BASE_API_URL =
  process.env.NEXT_PUBLIC_CHORD_OMS_API_URL || "http://localhost:3000";

const axiosClient = axios.create({
  baseURL: BASE_API_URL,
  headers: { "Content-Type": "application/json" },
  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => humps.camelizeKeys(data),
  ],
  transformRequest: [
    (data) => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest,
  ],
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      originalRequest.headers.Authorization &&
      !originalRequest._retry &&
      error.response.status === 401
    ) {
      originalRequest._retry = true;
      await axiosClient.store.dispatch(getToken());
      const state = axiosClient.store.getState();
      const authToken = state.auth.token;
      if (authToken) originalRequest.headers.Authorization = authToken;

      return axiosClient(originalRequest);
    }

    throw new ApiError(error);
  },
);

axiosClient.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };

    // if there is not an Authorization header present on the request, add the order token. Some API requests will fail if both are used.
    if (!newConfig.headers.Authorization) {
      const state = axiosClient.store.getState();
      const orderToken = state.cart.data.token;
      if (orderToken) newConfig.headers["X-Spree-Order-Token"] = orderToken;
    }

    const anonymousId = getAnonymousId();
    if (anonymousId) newConfig.headers["ACS-Anonymous-Id"] = anonymousId;

    newConfig.headers["ACS-Store"] = process.env.CHORD_OMS_STORE_NAME;

    return newConfig;
  },
  async (error) => {
    throw new Error(error);
  },
);

export default axiosClient;

export const axiosMiddleware = (store) => (next) => (action) => {
  axiosClient.store = store;
  next(action);
};
