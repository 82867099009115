import {
  LOAD_CART_REQUEST,
  LOAD_CART_SUCCESS,
  LOAD_CART_ERROR
} from '~/redux/actions/types'

import { forgetCart } from './forget-cart'

export const loadCartRequest = () => {
  return {
    type: LOAD_CART_REQUEST
  }
}

export const loadCartSuccess = cart => {
  return {
    type: LOAD_CART_SUCCESS,
    data: cart
  }
}

export const loadCartError = error => {
  return {
    type: LOAD_CART_ERROR,
    error: true,
    payload: error
  }
}

export const loadCart =
  () =>
  async (dispatch, getState, { api }) => {
    const createOrLoadCart = async cartNumber => {
      // if there is no cart token or number stored in Redux/localStorage, create a new order
      if (!cartNumber) return await api.createCart()

      try {
        const cart = await api.fetchCart(cartNumber)

        // if the cart token or number stored in Redux/localStorage has an order state of 'complete',
        // clear and create a new order confirm
        if (cart.state && cart.state === 'complete') {
          await dispatch(forgetCart())
          return await api.createCart()
        }

        return cart
      } catch (error) {
        if (error.status !== 401) throw error

        // if the cart token or number stored in Redux/localStorage is invalid, clear and create a new order
        await dispatch(forgetCart())
        return await api.createCart()
      }
    }

    const { cart } = getState()
    try {
      dispatch(loadCartRequest())
      const newCart = await createOrLoadCart(cart.data.number)
      dispatch(loadCartSuccess(newCart))
    } catch (error) {
      dispatch(loadCartError(error))
      throw error
    }
  }

export const loadCurrentCart =
  () =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch(loadCartRequest())
      const newCart = await api.fetchCurrentCart()
      dispatch(loadCartSuccess(newCart))
    } catch (error) {
      dispatch(loadCartError(error))
      throw error
    }
  }
