import {
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  CHECKOUT_ERROR
} from '~/redux/actions/types'
import { getOrLoadCart } from './get-or-load-cart'

export const checkoutCartRequest = () => {
  return {
    type: CHECKOUT_REQUEST
  }
}

export const checkoutCartSuccess = cart => {
  return {
    type: CHECKOUT_SUCCESS,
    data: cart
  }
}

export const checkoutCartError = error => {
  return {
    type: CHECKOUT_ERROR,
    error: true,
    payload: error
  }
}

export const checkout =
  () =>
  async (dispatch, getState, { api }) => {
    dispatch(checkoutCartRequest())

    try {
      const { data: cart } = await getOrLoadCart(dispatch, getState)
      const updatedCart = await api.checkout(cart)
      dispatch(checkoutCartSuccess(updatedCart))
      return updatedCart
    } catch (error) {
      dispatch(checkoutCartError(error))
      throw error
    }
  }
