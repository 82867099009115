import {
  AUTH_LOGOUT_SUCCESS,
  LOAD_USER_REFERRAL_IDENTIFIER_SUCCESS,
  LOAD_USER_SUCCESS,
  MODIFY_USER_SUCCESS,
  LOAD_CART_SUCCESS,
  LOAD_USER_SUBSCRIPTIONS_REQUEST,
  LOAD_USER_SUBSCRIPTIONS_SUCCESS,
  LOAD_USER_SUBSCRIPTIONS_ERROR
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const user = (state = initialState.user, action) => {
  switch (action.type) {
    case AUTH_LOGOUT_SUCCESS:
      return {
        data: {},
        referralIdentifier: null,
        subscriptions: []
      }

    case LOAD_USER_REFERRAL_IDENTIFIER_SUCCESS:
      return {
        ...state,
        referralIdentifier: action.payload.referralIdentifier
      }

    case LOAD_USER_SUCCESS:
    case MODIFY_USER_SUCCESS:
      return {
        ...state,
        data: action.payload.data
      }

    case LOAD_CART_SUCCESS: {
      if (!action.data.userId) return state

      return {
        ...state,
        data: {
          ...state.data,
          id: action.data.userId
        }
      }
    }

    case LOAD_USER_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      }

    case LOAD_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        subscriptions: action.payload.subscriptions
      }

    case LOAD_USER_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        isFetching: true,
        error: null
      }

    default:
      return state
  }
}

export default user
