/* global analytics */
import { getCookie } from "~/utils/cookies";
import * as typewriter from "./typewriter";
const sha1 = require("sha1");

/**
 * Records any actions our users perform, along with any properties that describe the action.
 *
 * @param name        The name of the event.
 *
 * @param properties  A dictionary of properties for the event. If the event was 'Added to Cart',
 *                    it might have properties like price and productType.
 *                    See https://segment.com/docs/sources/website/analytics.js/#default-properties
 */
const trackEvent = (name, properties) => {
  try {
    analytics.track(name, properties);
  } catch (error) {
    /*
      See https://segment.com/docs/sources/server/http/#errors:
      "We currently return a 200 response for all API requests so debugging should be done in the
      Segment Debugger. The only exception is if the request is too large / json is invalid it will
      respond with a 400."
      */
    if (error && error.response && error.response.status === 400) {
      console.error(error);
    }
  }
};

/**
 * Lets us tie users to their actions and record traits about them.
 *
 * @param userId      Optional user identifier. If not set, Segment will use an anonymous Id instead.
 *                    See https://segment.com/docs/connections/spec/identify/#anonymous-id
 *
 * @param properties  A dictionary of properties for the event. If the event was 'Added to Cart',
 *                    it might have properties like price and productType.
 *                    See https://segment.com/docs/sources/website/analytics.js/#default-properties
 *
 * @param options     A dictionary of options. For example, enable or disable specific destinations for the call.
 *                    See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#selecting-destinations
 *
 * @param callback    A function executed after a short timeout, giving the browser time to make outbound requests first.
 *
 */
const identifyUser = (userId, traits = {}, options = {}, callback) => {
  try {
    if (userId) {
      analytics.identify(userId, traits, options, callback);
    } else {
      analytics.identify(traits, options, callback);
    }
  } catch (error) {
    /*
      See https://segment.com/docs/sources/server/http/#errors:
      "We currently return a 200 response for all API requests so debugging should be done in the
      Segment Debugger. The only exception is if the request is too large / json is invalid it will
      respond with a 400."
      */
    if (error && error.response && error.response.status === 400) {
      console.error(error);
    }
  }
};

/**
 * Calling reset resets the id, including anonymousId, and clear traits for the currently identified user and group.
 */
const resetUser = () => {
  try {
    analytics.reset();
  } catch (error) {
    /*
      See https://segment.com/docs/sources/server/http/#errors:
      "We currently return a 200 response for all API requests so debugging should be done in the
      Segment Debugger. The only exception is if the request is too large / json is invalid it will
      respond with a 400."
      */
    if (error && error.response && error.response.status === 400) {
      console.error(error);
    }
  }
};

/**
 * Returns the anonymousId for the current client's session. This ID should be saved in
 * Local Storage. If it doesn't exist, Segment will create a new one for us.
 */
const getAnonymousId = () => {
  try {
    // analytics.user() will only be defined when Data Collection is enabled and Segment is loaded.
    if (!analytics?.user) {
      return;
    }

    const user = analytics.user();

    if (user === undefined) {
      return;
    } else if (user.id() !== null) {
      return user.id();
    } else {
      return user.anonymousId();
    }
  } catch (error) {
    /*
      See https://segment.com/docs/sources/server/http/#errors:
      "We currently return a 200 response for all API requests so debugging should be done in the
      Segment Debugger. The only exception is if the request is too large / json is invalid it will
      respond with a 400."
      */
    if (error && error.response && error.response.status === 400) {
      console.error(error);
    }
  }
};

const trackProductInCart = ({ cart, product, quantity }) => {
  const trackingProps = {
    ...trackKitOrProductProperties({ cart, product }),
    quantity,
  };

  if (quantity > 0) typewriter.productAdded(trackingProps);
  else typewriter.productRemoved(trackingProps);
};

const trackProductClicked = ({ cart, product }) => {
  const trackingProps = trackKitOrProductProperties({ cart, product: product });
  typewriter.productClicked(trackingProps);
};

// TODO: return proper payload; chord code expects different product model than what we use
const trackProductProperties = ({ cart, product }) => {
  const sku = product?.sku ? product.sku : product?.variants?.[0]?.sku;

  const url = `${window.location.protocol}//${window.location.hostname}/products/${product.slug}/`;

  const { price } = product;
  const imageUrl = product.mainImage?.url;
  const options = product.optionValues;

  return {
    brand: process.env.CHORD_OMS_BRAND_NAME,
    cart_id: cart.number,
    name: product.name,
    product_id: product.slug,
    sku,
    price,
    image_url: imageUrl,
    url,
    option_values: options,
  };
};

const trackKitProperties = ({ cart, kit }) => {
  let sku = "";

  if (kit.sku) {
    sku = kit.sku;
  } else {
    kit.products
      .map(({ variants }) => variants[0].sku)
      .sort()
      .join("|");
  }

  const url = `${window.location.protocol}//${window.location.hostname}/bundles/${kit.slug}/`;
  const price = kit.price;
  const imageUrl = kit.mainImage ? kit.mainImage.url : null;

  return {
    brand: process.env.CHORD_OMS_BRAND_NAME,
    cart_id: cart.number,
    name: kit.name,
    product_id: kit.slug,
    sku: sku,
    price: price,
    image_url: imageUrl,
    url: url,
  };
};

const trackKitOrProductProperties = ({ cart, product }) => {
  const isKit = product.products ? true : false;

  return isKit
    ? trackKitProperties({ cart, kit: product })
    : trackProductProperties({ cart, product: product });
};

const trackEmailCaptured = ({ name = null, email, placement }) => {
  return new Promise((resolve, reject) => {
    try {
      const identifyProps = { email };
      if (name) identifyProps.name = name;

      identifyUser("", identifyProps);
      typewriter.emailCaptured({ email, web_placement: placement });
      return resolve();
    } catch (error) {
      return reject(error);
    }
  });
};

const trackCartViewed = ({ cartNumber, lineItems }) => {
  const trackingProps = trackCartProperties({ cartNumber, lineItems });
  typewriter.cartViewed(trackingProps);
};

const trackCheckoutStarted = ({ cart, lineItems }) => {
  const trackCheckoutProperties = {
    affiliation: process.env.CHORD_OMS_BRAND_NAME,
    order_id: cart.number,
    currency: cart.currency,
    revenue: parseFloat(cart.total),
    discount:
      parseFloat(cart.additionalTaxTotal) - parseFloat(cart.adjustmentTotal),
    shipping: parseFloat(cart.shipTotal),
    tax: parseFloat(cart.taxTotal),
    total: parseFloat(cart.total),
    products: trackCartProductsProperties(lineItems),
  };

  const couponAdjustment = cart.lineItems
    .map((lineItem) => lineItem.adjustments)
    .flat()
    .find((adjustment) => adjustment.promotionCode);
  if (couponAdjustment) {
    trackCheckoutProperties.coupon = couponAdjustment.promotionCode.value;
  }

  typewriter.checkoutStarted(trackCheckoutProperties);
};

const trackCheckoutCompleted = ({ cart }) => {
  const checkoutCompletedProperties = {
    value: parseFloat(cart.total),
    currency: "USD",
    transaction_id: cart.number,
    discount:
      parseFloat(cart.additionalTaxTotal) - parseFloat(cart.adjustmentTotal),
    email_hash: sha1(cart.email),
    store: cart.store.code,
    tracking_ids: {},
  };

  const irClickId = getCookie("ir_click_id");

  if (irClickId) {
    checkoutCompletedProperties.tracking_ids.ir_click_id = irClickId;
  }

  const couponAdjustment = cart.lineItems
    .map((lineItem) => lineItem.adjustments)
    .flat()
    .find((adjustment) => adjustment.promotionCode);

  const products = cart.lineItems.map((lineItem) => {
    return {
      name: lineItem.variant.slug,
      sku: lineItem.variant.sku,
      price: lineItem.price,
      total: lineItem.total,
      quantity: lineItem.quantity,
    };
  });

  if (couponAdjustment) {
    checkoutCompletedProperties.coupon = couponAdjustment.promotionCode.value;
  }

  if (products) {
    checkoutCompletedProperties.products = products;
  }

  typewriter.checkoutCompleted(checkoutCompletedProperties);
};

const trackCheckoutEmailSubmitted = (userId, traits) => {
  return new Promise((resolve) => {
    identifyUser(userId, traits, {}, () => {
      return resolve();
    });
  });
};

const trackSignedOut = ({ userId, email }) => {
  return new Promise((resolve, reject) => {
    try {
      typewriter.signedOut({ userId: userId, email: email }, {}, () => {
        resetUser();
        return resolve();
      });
    } catch (error) {
      return reject(error);
    }
  });
};

const trackSignedIn = ({ userId, email }) => {
  return new Promise((resolve, reject) => {
    try {
      typewriter.signedIn({ userId: userId, email: email }, {}, () => {
        return resolve();
      });
    } catch (error) {
      return reject(error);
    }
  });
};

const trackSignedUp = ({ userId, email, signupType, username }) => {
  return new Promise((resolve, reject) => {
    try {
      typewriter.signedUp(
        {
          userId: userId,
          email: email,
          signup_type: signupType,
          username: username,
        },
        {},
        () => {
          return resolve();
        },
      );
    } catch (error) {
      return reject(error);
    }
  });
};

const trackLoginStarted = () => {
  return new Promise((resolve, reject) => {
    try {
      typewriter.loginStarted({}, {}, () => {
        return resolve();
      });
    } catch (error) {
      return reject(error);
    }
  });
};

const trackCartProperties = ({ cartNumber, lineItems }) => {
  return {
    brand: process.env.CHORD_OMS_BRAND_NAME,
    cart_id: cartNumber,
    products: trackCartProductsProperties(lineItems),
  };
};

const trackCartProductsProperties = (lineItems) => {
  return lineItems.map((item, index) => {
    return {
      position: index + 1,
      product_id: item.slug,
      sku: item.sku,
      variant: item.sku,
      option_values: item.optionValues
        ? item.optionValues.map((option) => option.slug)
        : null,
      name: item.name,
      brand: process.env.CHORD_OMS_BRAND_NAME,
      price: item.price,
      image_url: item.mainImage ? item.mainImage.url : null,
      quantity: item.quantity,
      url: item.url,
      coupon: "",
    };
  });
};

const trackCouponEntered = ({ cart, promoCode }) => {
  typewriter.couponEntered(trackCouponProperties({ cart, promoCode }));
};

const trackCouponApplied = ({ cart, promoCode, promoAmount }) => {
  typewriter.couponApplied({
    ...trackCouponProperties({ cart, promoCode }),
    discount: promoAmount,
  });
};

const trackCouponDenied = ({ cart, promoCode, reason }) => {
  typewriter.couponDenied({
    ...trackCouponProperties({ cart, promoCode }),
    reason,
  });
};

const trackCouponRemoved = ({ cart, promoCode, promoAmount }) => {
  typewriter.couponRemoved({
    ...trackCouponProperties({ cart, promoCode }),
    discount: promoAmount,
  });
};

const trackCouponProperties = ({ cart, promoCode }) => {
  return {
    cart_id: cart.number,
    coupon_id: promoCode,
    order_id: cart.number,
  };
};

const trackStockRequestCreated = ({ email, cart, product }) => {
  identifyUser("", { email });

  const trackingProps = {
    ...trackKitOrProductProperties({ cart, product: product }),
    email: email,
  };

  typewriter.stockRequestCreated(trackingProps);
};

const trackAmbassadorSignupCreated = ({
  email,
  instagram,
  name,
  phone_number,
  social_ambassador,
  state,
  sweattalk_host_ambassador,
  tiktok,
}) => {
  return new Promise((resolve, reject) => {
    try {
      const identifyProps = { email };
      if (name) identifyProps.name = name;

      identifyUser("", identifyProps);
      typewriter.ambassadorSignupCreated({
        email,
        instagram,
        name,
        phone_number,
        social_ambassador,
        state,
        sweattalk_host_ambassador,
        tiktok,
      });
      return resolve();
    } catch (error) {
      return reject(error);
    }
  });
};

const trackCollectionClicked = ({ title, slug, url }) => {
  const trackingProps = {
    brand: process.env.CHORD_OMS_BRAND_NAME,
    title: title,
    collection_id: slug,
    url: url,
  };
  typewriter.collectionClicked(trackingProps);
};

const trackSubscriptionSkipped = (subscription) => {
  typewriter.subscriptionOrderSkipped(
    trackSubscriptionProperties(subscription),
  );
};

const trackSubscriptionCancelled = (subscription) => {
  typewriter.subscriptionCancelled(trackSubscriptionProperties(subscription));
};

const trackSubscriptionResumed = (subscription) => {
  typewriter.subscriptionResumed(trackSubscriptionProperties(subscription));
};

const trackSubscriptionPaused = (subscription) => {
  typewriter.subscriptionPaused(trackSubscriptionProperties(subscription));
};

const trackSubscriptionProperties = (subscription) => {
  return {
    id: subscription.id,
    brand: process.env.CHORD_OMS_BRAND_NAME,
    state: subscription.state,
    interval_units: subscription.intervalUnits,
    interval_length: subscription.intervalLength,
    end_date: subscription.endDate ? subscription.endDate : undefined,
    actionable_date: subscription.actionableDate
      ? subscription.actionableDate
      : undefined,
    products: trackCartProductsProperties(subscription.lineItems),
    address: {
      name: subscription.shipAddress.name,
      address1: subscription.shipAddress.address1,
      address2: subscription.shipAddress.address2
        ? subscription.shipAddress.address2
        : undefined,
      city: subscription.shipAddress.city,
      country: subscription.shipAddress.country.iso,
      state: subscription.shipAddress.state.abbr,
      zipcode: subscription.shipAddress.zipcode,
    },
  };
};

export {
  trackEvent,
  getAnonymousId,
  identifyUser,
  resetUser,
  trackProductInCart,
  trackProductClicked,
  trackCollectionClicked,
  trackEmailCaptured,
  trackCartViewed,
  trackCheckoutCompleted,
  trackCheckoutStarted,
  trackCheckoutEmailSubmitted,
  trackSignedIn,
  trackSignedUp,
  trackSignedOut,
  trackLoginStarted,
  trackCouponApplied,
  trackCouponDenied,
  trackCouponEntered,
  trackCouponRemoved,
  trackStockRequestCreated,
  trackAmbassadorSignupCreated,
  trackSubscriptionCancelled,
  trackSubscriptionSkipped,
  trackSubscriptionResumed,
  trackSubscriptionPaused,
};
