import {
  ADD_TO_CART_ERROR,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS
} from '~/redux/actions/types'
import { getOrLoadCart } from './get-or-load-cart'

export const addToCartRequest = sku => {
  return {
    type: ADD_TO_CART_REQUEST,
    sku: sku
  }
}

export const addToCartSuccess = (cart, variant, quantity) => {
  return {
    type: ADD_TO_CART_SUCCESS,
    data: cart,
    variant,
    quantity
  }
}

export const addToCartError = (error, meta = {}) => {
  return {
    type: ADD_TO_CART_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const addToCart =
  (sku, quantity) =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(addToCartRequest(sku))

      const { data: cart } = await getOrLoadCart(dispatch, getState)

      await api.addToCart(cart, sku, quantity)

      const updatedCart = await api.fetchCart(cart.number)

      const addLineItem = updatedCart.lineItems.find(
        li => li.variant.sku === sku
      )
      const variant = addLineItem.variant
      dispatch(addToCartSuccess(updatedCart, variant, quantity))
    } catch (error) {
      dispatch(addToCartError(error, { sku, quantity }))
      throw error
    }
  }
