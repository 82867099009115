import { clearSession } from '~/redux/actions/auth'

export const session = store => next => action => {
  const result = next(action)

  const { error, payload } = action

  if (
    error &&
    payload?.name === 'OMSError' &&
    payload?.code === 'UNAUTHORIZED'
  ) {
    store.dispatch(clearSession())
  }

  return result
}
