import { AuthErrorCodes } from "~/services/auth/error";

import {
  AUTH_IDENTIFY_ERROR,
  AUTH_IDENTIFY_REQUEST,
  AUTH_IDENTIFY_SUCCESS,
} from "~/redux/actions/types";
import auth from "~/services/auth";

export const identifyRequest = (email) => {
  return {
    type: AUTH_IDENTIFY_REQUEST,
    payload: {
      email,
    },
  };
};

export const identifySuccess = (token) => {
  return {
    type: AUTH_IDENTIFY_SUCCESS,
    payload: {
      token,
    },
  };
};

export const identifyError = (error, meta = {}) => {
  return {
    type: AUTH_IDENTIFY_ERROR,
    error: true,
    meta,
    payload: {
      error,
    },
  };
};

export const handleCallback = () => async (dispatch) => {
  try {
    dispatch(identifyRequest());
    const token = await auth.handleCallback();
    dispatch(identifySuccess(token));
  } catch (error) {
    dispatch(identifyError(error));
    throw error;
  }
};

export const getToken = () => async (dispatch) => {
  try {
    dispatch(identifyRequest());
    const token = await auth.getToken();
    dispatch(identifySuccess(token));
  } catch (error) {
    if (error.code !== AuthErrorCodes.Unauthorized) {
      dispatch(identifyError(error));
    }
    throw error;
  }
};
