import {
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_IDENTIFY_SUCCESS,
} from "../types";
import auth from "../../../services/auth";
import { associateCartWithUser, loadCurrentCart } from "../cart";

export const loginRequest = (email) => ({
    type: AUTH_LOGIN_REQUEST,
    payload: {
      email,
    },
  });

export const loginSuccess = (payload) => ({
    type: AUTH_LOGIN_SUCCESS,
    payload,
  });

export const loginError = (error, meta = {}) => ({
    type: AUTH_LOGIN_ERROR,
    error: true,
    meta,
    payload: {
      error,
    },
  });

const identifySuccess = (token) => ({
    type: AUTH_IDENTIFY_SUCCESS,
    payload: {
      token,
    },
  });

export const login =
  ({ email, showUI = false, redirectURI = window.location.href }) =>
  async (dispatch, getState) => {
    const { cart } = getState();

    try {
      dispatch(loginRequest(email));
      await auth.login({ email, showUI, redirectURI });
      const token = await auth.getToken();

      dispatch(identifySuccess(token));

      // if there is a guest order in progress, associate it with the now-logged-in user.
      // if there is not a guest order in progress, replace it with the authenticated user's current order.

      const isOrderInProgress =
        cart.data.lineItems && cart.data.lineItems.length;
      try {
        if (isOrderInProgress) {
          await dispatch(associateCartWithUser());
        } else {
          await dispatch(loadCurrentCart());
        }
      } catch (error) {
        console.log(error);
      }

      const metadata = await auth.getUser();
      dispatch(loginSuccess({ email: metadata.email, token }));
    } catch (error) {
      dispatch(loginError(error, { email, showUI, redirectURI }));
      throw error;
    }
  };
