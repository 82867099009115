import {
  MODIFY_CART_ERROR,
  MODIFY_CART_QUANTITY_ERROR,
  MODIFY_CART_QUANTITY_REQUEST,
  MODIFY_CART_QUANTITY_SUCCESS,
  MODIFY_CART_REQUEST,
  MODIFY_CART_SUCCESS,
  MODIFY_CART_GIFT_CARDS_ERROR,
  MODIFY_CART_GIFT_CARDS_SUCCESS,
  MODIFY_CART_GIFT_CARDS_REQUEST
} from '~/redux/actions/types'
import { getOrLoadCart } from './get-or-load-cart'

export const modifyQuantityRequest = lineItemId => {
  return {
    type: MODIFY_CART_QUANTITY_REQUEST,
    lineItem: lineItemId
  }
}

export const modifyQuantitySuccess = (cart, variant, quantity) => {
  return {
    type: MODIFY_CART_QUANTITY_SUCCESS,
    data: cart,
    variant,
    quantity
  }
}

export const modifyQuantityError = (error, meta = {}) => {
  return {
    type: MODIFY_CART_QUANTITY_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const modifyQuantity =
  (lineItemId, quantity) =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(modifyQuantityRequest(lineItemId))

      const { data: cart } = await getOrLoadCart(dispatch, getState)

      await api.updateCartItem(cart, lineItemId, quantity)

      const updatedCart = await api.fetchCart(cart.number)

      const modifiedLineItem = cart.lineItems.find(li => li.id === lineItemId)
      const variant = modifiedLineItem.variant
      const updatedQuantity = quantity - modifiedLineItem.quantity

      dispatch(modifyQuantitySuccess(updatedCart, variant, updatedQuantity))
    } catch (error) {
      dispatch(modifyQuantityError(error, { lineItemId, quantity }))
    }
  }

export const modifyGiftCardsRequest = response => ({
  type: MODIFY_CART_GIFT_CARDS_REQUEST,
  giftCardDetails: response
})

export const modifyGiftCardsSuccess = (cart, response) => ({
  type: MODIFY_CART_GIFT_CARDS_SUCCESS,
  data: cart,
  response
})

export const modifyGiftCardsError = (error, meta = {}) => ({
    type: MODIFY_CART_GIFT_CARDS_ERROR,
    error: true,
    meta,
    payload: error
})

export const modifyGiftCards = (giftCardDetails) =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(modifyGiftCardsRequest(giftCardDetails))

      const { data: cart } = await getOrLoadCart(dispatch, getState)

      const response = await api.updateGiftCards(cart, giftCardDetails)

      const updatedCart = await api.fetchCart(cart.number)

      dispatch(modifyGiftCardsSuccess(updatedCart, response))
    } catch (error) {
      dispatch(modifyGiftCardsError(error))
    }
  }

export const modifyCartRequest = props => {
  return {
    type: MODIFY_CART_REQUEST,
    data: props
  }
}

export const modifyCartSuccess = cart => {
  return {
    type: MODIFY_CART_SUCCESS,
    data: cart
  }
}

export const modifyCartError = (error, meta = {}) => {
  return {
    type: MODIFY_CART_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const modifyCart =
  props =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(modifyCartRequest(props))

      const { data: cart } = await getOrLoadCart(dispatch, getState)

      const updatedCart = await api.updateCart(cart, props)

      dispatch(modifyCartSuccess(updatedCart))
    } catch (error) {
      dispatch(modifyCartError(error, props))
    }
  }
