import qs from "query-string";
import { getToken } from "../../redux/actions";
import axiosClient from "./axios-client";

/* eslint-disable class-methods-use-this */
class Api {
  async getAuthorizationHeader() {
    if (!axiosClient.store) {
      return null;
    }

    const { auth } = axiosClient.store.getState();
    // if there is a current auth token, use it
    if (auth.token) {
      return auth.token;
    }

    // if there is not a current auth token, but the user is logged in, retrieve an auth token
    if (auth.isLoggedIn) {
      await axiosClient.store.dispatch(getToken());
    }
    const { auth: updatedAuth } = axiosClient.store.getState();

    if (updatedAuth.token) {
      return updatedAuth.token;
    }

    return null;
  }

  async findOrCreateCart() {
    let cart = await this.fetchCurrentCart();
    if (!cart) {
      cart = await this.createCart();
    }
    return cart;
  }

  async createCart() {
    const url = "/api/orders";
    const payload = { line_items_attributes: [] };
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.post(url, payload, config);
    return response.data;
  }

  async fetchCart(number) {
    const url = `/api/orders/${number}`;
    const response = await axiosClient.get(url);
    return response.data;
  }

  async fetchCurrentCart() {
    const url = `/api/orders/current`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.get(url, config);
    return response.data;
  }

  async updateCart(cart, props) {
    const url = `/api/orders/${cart.number}`;
    const response = await axiosClient.patch(url, { id: cart.id, ...props });
    return response.data;
  }

  async addToCart(cart, sku, quantity) {
    const url = `/api/orders/${cart.number}/line_items`;

    const payload = {
      line_item: {
        sku,
        quantity,
      },
    };

    const response = await axiosClient.post(url, payload);
    return response.data;
  }

  async removeFromCart(cart, lineItemId) {
    const url = `/api/orders/${cart.number}/line_items/${lineItemId}`;
    return axiosClient.delete(url);
  }

  async updateCartItem(cart, lineItemId, quantity) {
    const url = `/api/orders/${cart.number}/line_items/${lineItemId}`;
    const payload = {
      quantity,
    };
    const response = await axiosClient.patch(url, payload);
    return response.data;
  }

  async updateGiftCards(cart, giftCardsDetails) {
    const url = `/api/orders/${cart.number}/gift_cards/bulk_update`;
    const payload = {
      giftCards: giftCardsDetails,
    };
    const response = await axiosClient.patch(url, payload);
    return response.data;
  }

  async addPromoCode(cart, promoCode) {
    const url = `/api/orders/${cart.number}/coupon_codes`;
    const payload = { coupon_code: promoCode };
    const response = await axiosClient.post(url, payload);
    return response.data;
  }

  async removePromoCode(cart, promoCode) {
    const url = `/api/orders/${cart.number}/coupon_codes/${promoCode}`;
    const response = await axiosClient.delete(url);
    return response.data;
  }

  async checkout(cart) {
    const url = `/api/checkouts/${cart.number}/prepare`;
    const response = await axiosClient.put(url);
    return response.data;
  }

  async finalizeCheckout({ orderNumber, checkoutSessionId }) {
    const url = `/api/checkouts/${orderNumber}/finalize`;
    const payload = { checkout_session_id: checkoutSessionId };
    const response = await axiosClient.put(url, payload);
    return response.data;
  }

  async fetchUserReferralIdentifier(email) {
    const url = "/api/referral_identifiers";
    const payload = { email };
    const response = await axiosClient.post(url, payload, {
      transformRequest: [
        (data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers["X-Spree-Order-Token"];
          return JSON.stringify(data);
        },
      ],
    });
    return response.data;
  }

  async subscribeProduct(cart, { sku, quantity, interval, endDate = {} }) {
    const url = `/api/orders/${cart.number}/line_items`;

    const payload = {
      line_item: {
        sku,
        quantity,
      },
      subscription_line_item: {
        interval_length: interval.length,
        interval_units: interval.unit,
        end_date: endDate,
      },
    };

    const response = await axiosClient.post(url, payload);
    return response.data;
  }

  async associateUser(cart) {
    const url = `/api/orders/${cart.number}/associate_user?order_token=${cart.token}`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.put(url, null, config);
    return response.data;
  }

  async fetchUser() {
    const url = `/api/users/me`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) {
      config.headers.Authorization = authToken;
    }

    const response = await axiosClient.get(url, config);
    return response.data;
  }

  async updateUser(id, props) {
    const url = `/api/users/${id}`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.patch(url, props, config);
    return response.data;
  }

  async fetchSubscriptions(userId) {
    const url = `/api/users/${userId}/subscriptions`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.get(url, config);
    return response.data.subscriptions;
  }

  async skipSubscription(userId, subscriptionId) {
    const url = `/api/users/${userId}/subscriptions/${subscriptionId}/skip`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.post(url, null, config);
    return response.data;
  }

  async loadSubscription(userId, subscriptionId) {
    const url = `/api/users/${userId}/subscriptions/${subscriptionId}`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.get(url, config);
    return response.data;
  }

  async cancelSubscription(userId, subscriptionId) {
    const url = `/api/users/${userId}/subscriptions/${subscriptionId}/cancel`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.post(url, null, config);
    return response.data;
  }

  async updateSubscription(userId, subscriptionId, props) {
    const url = `/api/users/${userId}/subscriptions/${subscriptionId}`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.put(url, props, config);
    return response.data;
  }

  async resumeSubscription(userId, subscriptionId, props) {
    const url = `/api/users/${userId}/subscriptions/${subscriptionId}/resume`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.post(url, props, config);
    return response.data;
  }

  async pauseSubscription(userId, subscriptionId, props) {
    const url = `/api/users/${userId}/subscriptions/${subscriptionId}/pause`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.post(url, props, config);
    return response.data;
  }

  async getVariantsBySkus(skus) {
    const url = `/api/variants`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();

    if (authToken) config.headers.Authorization = authToken;

    const response = await axiosClient.get(url, {
      ...config,
      params: {
        "q[sku_in]": skus,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "bracket" }),
    });

    return response.data.variants;
  }

  async createStockRequest({ email, sku }) {
    const url = `/api/stock_requests`;
    const config = {
      headers: {},
      transformRequest: [
        (data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers["X-Spree-Order-Token"];
          return JSON.stringify(data);
        },
      ],
    };

    const props = { stock_request: { email, sku } };

    const response = await axiosClient.post(url, props, config);
    return response;
  }

  /**
   * @param {number} chordUserId
   * @returns {Promise<any>}
   */
  async fetchAddressBook(chordUserId) {
    const url = `/api/users/${chordUserId}/address_book`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) {
      config.headers.Authorization = authToken;
    }
    const response = await axiosClient.get(url, config);
    return response.data;
  }

  /**
   *
   * @param {number} chordUserId
   * @param {import("../../../../../../component-utils/account-addresses").ChordAddress} address
   * @returns {Promise<any>}
   */
  async updateAddressBook(chordUserId, address) {
    const url = `/api/users/${chordUserId}/address_book`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) {
      config.headers.Authorization = authToken;
    }
    const response = await axiosClient.patch(url, address, config);
    return response.data;
  }

  /**
   *
   * @param {number} chordUserId
   * @param {import("../../../../../../component-utils/account-addresses").ChordAddress} address
   * @returns {Promise<any>}
   */
  async removeFromAddressBook(chordUserId, address) {
    const url = `/api/users/${chordUserId}/address_book?address_id=${address.id}`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) {
      config.headers.Authorization = authToken;
    }
    const response = await axiosClient.delete(url, config);
    return response.data;
  }

  /**
   * Orders
   */
  async fetchOrders() {
    const url = `/api/orders/mine`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) {
      config.headers.Authorization = authToken;
    }
    const response = await axiosClient.get(url, config);
    return response.data.orders;
  }

  /**
   *
   * @param {string} orderNumber
   * @returns {Promise<any>}
   */
  async fetchOrder(orderNumber) {
    const url = `/api/orders/${orderNumber}`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) {
      config.headers.Authorization = authToken;
    }
    const response = await axiosClient.get(url, config);
    return response.data;
  }

  async fetchAccountCredit(chordUser) {
    const url = `/api/hub/customers/${chordUser.id}/store_credits`;
    const config = { headers: {} };

    const authToken = await this.getAuthorizationHeader();
    if (authToken) {
      config.headers.Authorization = authToken;
    }
    const response = await axiosClient.get(url, config);
    return response.data;
  }
}

const api = new Api();

export default api;

// TODO: Replace the whole thing with:

// import { createChordClient } from '@chordcommerce/chord-js-autonomy'

// const baseURL = process.env.CHORD_OMS_API_URL
// const storeID = process.env.CHORD_STORE_ID

// const client = createChordClient({
//   baseURL,
//   storeID
// })

// export default client
