export const AuthErrorCodes = {
  Unauthorized: 'UNAUTHORIZED',
  InternalError: 'INTERNAL_ERROR'
}

export class AuthError extends Error {
  constructor({ message = '', code = AuthErrorCodes.InternalError }) {
    super(message)

    this.name = 'AuthError'
    this.code = code
  }
}
