import storage from "../../services/storage";

export const persist = (store) => (next) => (action) => {
  const previousState = store.getState();
  const result = next(action);
  const nextState = store.getState();

  if (previousState.cart.data.number !== nextState.cart.data.number) {
    storage.setItem("cart-number", nextState.cart.data.number);
  }

  if (previousState.cart.data.token !== nextState.cart.data.token) {
    storage.setItem("cart-token", nextState.cart.data.token);
  }

  if (previousState.auth.isLoggedIn !== nextState.auth.isLoggedIn) {
    storage.setItem("auth-logged-in", nextState.auth.isLoggedIn);
  }

  return result;
};
