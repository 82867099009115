import {
  LOAD_ADDRESS_BOOK_REQUEST,
  LOAD_ADDRESS_BOOK_SUCCESS,
  LOAD_ADDRESS_BOOK_ERROR,
  UPDATE_ADDRESS_BOOK_REQUEST,
  UPDATE_ADDRESS_BOOK_SUCCESS,
  UPDATE_ADDRESS_BOOK_ERROR,
} from "../actions/types";
import initialState from "../store/initial-state";

// eslint-disable-next-line default-param-last
const orders = (state = initialState.addressBook, action) => {
  switch (action.type) {
    case LOAD_ADDRESS_BOOK_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
      };
    case LOAD_ADDRESS_BOOK_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case LOAD_ADDRESS_BOOK_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };

    case UPDATE_ADDRESS_BOOK_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
      };
    case UPDATE_ADDRESS_BOOK_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case UPDATE_ADDRESS_BOOK_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default orders;
