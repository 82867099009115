import {
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR
} from '~/redux/actions/types'
import { loadUserReferralIdentifier } from './get-or-load-referral-identifier'

export const loadUserRequest = () => ({
  type: LOAD_USER_REQUEST
})

export const loadUserSuccess = data => ({
  type: LOAD_USER_SUCCESS,
  payload: {
    data
  }
})

export const loadUserError = error => ({
  type: LOAD_USER_ERROR,
  payload: error,
  error: true
})

export const loadUser =
  email =>
  async (dispatch, _getState, { api }) => {
    // this if statement is to preserve backwards compatibility for this method.
    if (email) {
      return dispatch(loadUserReferralIdentifier(email))
    }

    dispatch(loadUserRequest())
    try {
      const response = await api.fetchUser()
      dispatch(loadUserSuccess(response))
    } catch (error) {
      dispatch(loadUserError(error))
      throw error
    }
  }
