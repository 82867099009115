/* eslint-disable import/no-unresolved */
import { applyMiddleware, createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import { createWrapper } from "next-redux-wrapper";
import * as defaultReducer from "../reducers";
import defaultInitialState from "./initial-state";
import { axiosMiddleware } from "../../services/api/axios-client";
import {
  persist as persistMiddleware,
  session as sessionMiddleware,
  analytics as analyticsMiddleware,
} from "../middleware";
import api from "../../services/api";
import { initializeState } from "../actions";

const isBrowser = typeof window !== "undefined";

const enhancers = composeWithDevTools({});

const makeStore = (reducer = {}, preloadedState = {}, middleware = []) => {
  const storeReducer = combineReducers({ ...defaultReducer, ...reducer });

  const storePreloadedState = { ...defaultInitialState, ...preloadedState };

  const storeEnhancer = enhancers(
    applyMiddleware(
      thunk.withExtraArgument({ api }),
      axiosMiddleware,
      persistMiddleware,
      analyticsMiddleware,
      sessionMiddleware,
      ...middleware,
    ),
  );

  const store = createStore(storeReducer, storePreloadedState, storeEnhancer);

  if (isBrowser) store.dispatch(initializeState());

  return store;
};

export const wrapper = createWrapper(makeStore, {
  debug: process.env.NODE_ENV === "development",
});
