import {
  MODIFY_USER_REQUEST,
  MODIFY_USER_SUCCESS,
  MODIFY_USER_ERROR
} from '~/redux/actions/types'

export const modifyUserRequest = () => ({
  type: MODIFY_USER_REQUEST
})

export const modifyUserSuccess = data => ({
  type: MODIFY_USER_SUCCESS,
  payload: {
    data
  }
})

export const modifyUserError = error => ({
  type: MODIFY_USER_ERROR,
  payload: error,
  error: true
})

export const modifyUser =
  props =>
  async (dispatch, getState, { api }) => {
    dispatch(modifyUserRequest())
    try {
      const { user } = getState()
      const userId = user.data.id
      const response = await api.updateUser(userId, { id: userId, ...props })
      dispatch(modifyUserSuccess(response))
      return response
    } catch (error) {
      dispatch(modifyUserError(error))
      throw error
    }
  }
