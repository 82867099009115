import {
  LOAD_ORDER_REQUEST,
  LOAD_ORDER_SUCCESS,
  LOAD_ORDER_ERROR,
} from "../actions/types";
import initialState from "../store/initial-state";

// eslint-disable-next-line default-param-last
const order = (state = initialState.order, action) => {
  switch (action.type) {
    case LOAD_ORDER_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
      };
    case LOAD_ORDER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case LOAD_ORDER_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default order;
